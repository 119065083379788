import React from 'react'
import Header from '../components/HeaderAdmin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser, faPhone, faEnvelope, faKey, faMapMarkerAlt, faAddressBook, faEdit } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const Profile = () => {
    const user = useSelector(selectUser);
    console.log(user);
    
  return (
    <div className="min-h-screen flex flex-col">
        <Header />
        <div className="flex-grow pt-20 flex items-center justify-center bg-gradient-to-br from-green-900 via-green-500 to-green-200">
    <div className="bg-white p-8 shadow-lg w-full max-w-md">
    <h2 className="text-xl font-bold text-center p-1 text-black">Profile:</h2> 
    <div className="flex flex-col">
        <div className="flex flex-row justify-between"><p><FontAwesomeIcon icon={faBuilding} /></p><p>{user.result.company}</p>
        </div>
        <div className="flex flex-row justify-between"><p><FontAwesomeIcon icon={faUser} /></p><p>{user.result.person}</p>
        </div>
        <div className="flex flex-row justify-between"><p><FontAwesomeIcon icon={faPhone} /></p><p>{user.result.phone}</p>
        </div>
        <div className="flex flex-row justify-between"><p><FontAwesomeIcon icon={faEnvelope} /></p><p>{user.result.email}</p>
        </div>
        <div className="flex flex-row justify-between"><p><FontAwesomeIcon icon={faMapMarkerAlt} /></p><p>{user.result.city}</p>
        </div>
        <div className="flex flex-row justify-between"><p><FontAwesomeIcon icon={faAddressBook} /></p><p>{user.result.address}</p>
        </div>
        <div className="flex flex-row justify-between"><p><FontAwesomeIcon icon={faKey} /></p><p>App password</p>
        </div>
    </div>
    <button className="w-full bg-black py-1 mt-2 text-white"> <Link to="/editProfile">EDIT</Link> </button>
    </div>
        </div></div>
  )
}

export default Profile;