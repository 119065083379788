import React, { useEffect, useState } from 'react';
import { fetchArchiveImages } from '../redux/features/archiveSlice';
import { useDispatch, useSelector } from 'react-redux';
import { deleteArchive } from '../redux/features/archiveSlice';
import { selectUser } from '../redux/features/authSlice';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faBars } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import SessionsHistoryData from './SessionsHistoryData';
import { useTranslation } from 'react-i18next';

const SessionsHistory = ({goToSessions, archives}) => {
    const [showData, setShowData] = useState(false);
    const [archiveData, setArchiveData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    
    const getCurrentMonth = () => {
      const today = new Date();
      return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, "0")}`;
    };
    const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());

    const user = useSelector(selectUser);
    const userId = user?.result._id;
    const dispatch = useDispatch();
    const archiveImages = useSelector((state) => state.archive.archiveImages);

    // Pratimo kada su slike učitane
    useEffect(() => {
        if (archiveData && archiveImages[archiveData._id]) {
            console.log('Archive images loaded:', archiveImages[archiveData._id]);
            setIsLoading(false);
        }
    }, [archiveImages, archiveData]);

    const handleDelete = (id) =>{
        if(window.confirm(t('sessions.deleteConfirm'))){
            dispatch(deleteArchive({id, toast}))
        }
    }

    const openShowData = async(id) => {
        const selectedArchive = archives.find(client => client._id === id);
        setArchiveData(selectedArchive);
        
        // Sačekaj da se setArchiveData završi
        setTimeout(() => {
            setShowData(true);
            
            // Učitaj slike ako već nisu učitane
            if (!archiveImages[id]) {
                setIsLoading(true);
                dispatch(fetchArchiveImages(id))
                    .catch(error => {
                        console.error("Error loading images:", error);
                        setIsLoading(false);
                    });
            }
        }, 0);
    }

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };
    
    const filteredArchives = archives.filter((item) => {
        if (!selectedMonth) return true;
        const itemDate = new Date(item.date);
        const itemYearMonth = `${itemDate.getFullYear()}-${String(itemDate.getMonth() + 1).padStart(2, "0")}`;
        return itemYearMonth === selectedMonth;
    });
    
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        return `${day}.${month}`;
    };

    return (
        <div className="">
            <h2 className="text-xl font-bold text-white mb-1">{t('history.archivedSessions')}</h2>
            <label htmlFor="monthPicker" className="text-white font-bold mr-2">{t('history.selectMonth')}:</label>
            <input
                type="month"
                id="monthPicker"
                value={selectedMonth}
                onChange={handleMonthChange}
                className="border border-gray-300 rounded-md p-1"
            />
            <ul className="mt-4 text-sm font-bold text-white">
                {filteredArchives.map((archive, index) => (
                    <div key={archive._id}>
                        <li className="flex flex-row p-1 justify-between">
                            {`${new Date(archive.date).toLocaleDateString('sr-RS', {
                                day: '2-digit',
                                month: '2-digit',
                            })}`}
                            <span>{archive.firstName} {archive.lastName}</span>
                            <span className="flex flex-row space-x-2">
                                <button className="text-sm hover:text-base hover:text-gray-900" onClick={()=>handleDelete(archive._id)}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                                <button className="text-sm hover:text-base hover:text-gray-900" onClick={()=>openShowData(archive._id)}>
                                    <FontAwesomeIcon icon={faBars} />
                                </button>
                            </span>
                        </li>
                        <hr/>
                    </div>
                ))}
            </ul>
            <div className="overflow-x-auto w-full">
            </div>
            {showData && archiveData && (
                <SessionsHistoryData 
                    archives={archiveData} 
                    archiveImages={archiveImages[archiveData._id]} 
                    isLoading={isLoading}
                    closePopup={() => {
                        setShowData(false);
                        setArchiveData(null);
                    }}
                />
            )}
        </div>
    )
}

export default SessionsHistory