import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { login } from '../redux/features/authSlice.js';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext.js';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

const Login = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentLanguage } = useLanguage();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        try {
            const result = await dispatch(login(formData)).unwrap();
           
            if (result && result.token && result.result) {
                localStorage.setItem("Profile", JSON.stringify({ ...result }));
                toast.success(t('auth.loginSuccess'));
                navigate('/admin');
            }
        } catch (error) {
            console.error('Login error:', error);
            if (error.message === "User doesn't exist") {
                toast.error(t('auth.userNotFound'));
            } else if (error.message === "Invalid credentials") {
                toast.error(t('auth.invalidCredentials'));
            } else {
                toast.error(t('auth.somethingWentWrong'));
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="min-h-screen flex flex-col">
            {/* Header */}
            <Header />

            {/* Main Content */}
            <div className="flex-grow pt-20 flex items-center justify-center bg-gradient-to-br from-green-900 via-green-500 to-green-200">
                <div className="bg-white p-8 shadow-lg w-full max-w-md">
                    <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                        {t('auth.login')}
                    </h2>
                    <form className="mt-4 space-y-6" onSubmit={handleSubmit}>
                        <div className="rounded-md shadow-sm space-y-4">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('auth.email')}
                                </label>
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                                    placeholder={t('auth.emailPlaceholder')}
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('auth.password')}
                                </label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    required
                                    className="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                                    placeholder={t('auth.passwordPlaceholder')}
                                    value={formData.password}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="flex flex-col items-center justify-between">
                            <div className="text-center">
                                <label htmlFor="remember_me" className="text-md text-gray-900">
                                    {t('auth.noAccount')}
                                    <Link to="/register" className="text-green-700 underline ml-1">
                                        {t('auth.register')}
                                    </Link>
                                </label>
                            </div>
                            <div className="text-sm mt-2">
                                <Link to="/forgot-password" className="text-green-900 underline">
                                    {t('auth.forgotPassword')}
                                </Link>
                            </div>
                        </div>

                        <div>
                            <button
                                type="submit"
                                disabled={isLoading}
                                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black transition-colors duration-200 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                {isLoading ? t('common.loading') : t('auth.login')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;