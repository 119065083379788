import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteCategory, updateCategory, createCategory } from '../redux/features/categorySlice';
import { toast } from 'react-toastify';
import CategoryData from './CategoryData'
import { useNavigate } from 'react-router-dom';
import ColorDropdown from '../components/ColorDropdown';

const Categories = ({ categories }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showData, setShowData] = useState(false);
  const [categoryData, setCategoryData] = useState();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [colorFinish, setColorFinish] = useState('darkgray');
  const [formData, setFormData] = useState({
    category: "",
    colorFinish: "darkgray"
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const takeColor = (color) => {
    setColorFinish(color);
    setFormData(prev => ({
      ...prev,
      colorFinish: color
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (showEditForm && selectedCategory) {
      const id = selectedCategory._id;
      const updatedCategoryData = {
        ...formData,
        colorFinish: formData.colorFinish || colorFinish
      };
      
      dispatch(updateCategory({ id, updatedCategoryData, navigate, toast }));
    } else {
      const categoryData = {
        category: formData.category,
        colorFinish: formData.colorFinish || colorFinish
      };
      
      dispatch(createCategory({ categoryData, navigate, toast }));
    }
    
    setFormData({
      category: "",
      colorFinish: "darkgray"
    });
    setShowAddForm(false);
    setShowEditForm(false);
  };

  const handleEdit = (category) => {
    setSelectedCategory(category);
    setFormData({
      category: category.category,
      colorFinish: category.colorFinish || 'darkgray'
    });
    setColorFinish(category.colorFinish || 'darkgray');
    setShowEditForm(true);
  };

  const handleDelete = (category) => {
    setSelectedCategory(category);
    setShowDeleteConfirm(true);
  };

  const confirmDelete = () => {
    const id = selectedCategory._id;
    dispatch(deleteCategory({ id, toast }));
    setShowDeleteConfirm(false);
  };

  const openShowData = (id) => {
    const selectedCategory = categories.find(category => category._id === id);
    setCategoryData(selectedCategory); 
    setShowData(true); 
  }

  return (
    <div>
      {!showAddForm && !showEditForm && (
        <button
          onClick={() => setShowAddForm(true)}
          className="bg-green-500 text-white px-4 py-2 rounded mb-4"
        >
          {t('categories.addCategory') || "Add Category"}
        </button>
      )}

      {(showAddForm || showEditForm) && (
        <form onSubmit={handleSubmit} className="space-y-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-white mb-1">
              {t('categories.name') || "Category Name"}
            </label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              required
              placeholder={t('categories.namePlaceholder') || "Enter category name"}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-white mb-1">
              {t('services.chooseColorOfCategory') || "Choose Color"}
            </label>
            <ColorDropdown forwardColor={takeColor} initialColor={formData.colorFinish} />
          </div>
          
          <div className="flex gap-2">
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded"
            >
              {showEditForm ? (t('categories.editCategory') || "Update Category") : (t('categories.addCategory') || "Add Category")}
            </button>
            <button
              type="button"
              onClick={() => {
                setShowAddForm(false);
                setShowEditForm(false);
                setFormData({
                  category: "",
                  colorFinish: "darkgray"
                });
              }}
              className="bg-gray-500 text-white px-4 py-2 rounded"
            >
              {t('common.cancel') || "Cancel"}
            </button>
          </div>
        </form>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {categories && categories.length > 0 ? categories.map((category) => (
          <div
            key={category._id}
            className="p-4 rounded shadow"
            style={{ backgroundColor: category.colorFinish || '#f5f5f5' }}
          >
            <h3 className="text-lg font-semibold">{category.category}</h3>
            <div className="mt-4 flex gap-2">
              <button
                onClick={() => handleEdit(category)}
                className="bg-blue-500 text-white px-3 py-1 rounded"
              >
                <FontAwesomeIcon icon={faEdit} />
              </button>
              <button
                onClick={() => handleDelete(category)}
                className="bg-red-500 text-white px-3 py-1 rounded"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              <button
                onClick={() => openShowData(category._id)}
                className="bg-gray-700 text-white px-3 py-1 rounded"
              >
                <FontAwesomeIcon icon={faFolder} />
              </button>
            </div>
          </div>
        )) : (
          <p className="text-white">No categories available</p>
        )}
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <p className="mb-4">{t('categories.confirmDelete') || "Are you sure you want to delete this category?"}</p>
            <div className="flex gap-2">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                {t('categories.yes') || "Yes"}
              </button>
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                {t('categories.no') || "No"}
              </button>
            </div>
          </div>
        </div>
      )}

      {showData && (
        <CategoryData categoryData={categoryData} closePopup={() => setShowData(false)}/>
      )}
    </div>
  );
};

export default Categories;