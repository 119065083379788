import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import FileBase from "react-file-base64";
import { faPhone, faEnvelope, faTimes, faClock, faUserTie, faUser, faMoneyBill, faBrush } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';
import { toast } from 'react-toastify';
import { createArchive } from '../redux/features/archiveSlice';
import { deleteSession, getUserSessions } from '../redux/features/sessionSlice';
import { useTranslation } from 'react-i18next';

const SessionData = ({sessionData, closePopup}) => {
  const [archiveData, setArchiveData] = useState(sessionData);
  const [comment, setComment] = useState("");
  const user = useSelector(selectUser);
  const [sessionView, setSessionView] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const messages = {
    sessionAdded: t('toast.sessionAdded'),
    sessionDeleted: t('toast.sessionDeleted'),
    sessionUpdated: t('toast.sessionUpdated'),
    archiveAdded: t('toast.archiveAdded'),
    archiveDeleted: t('toast.archiveDeleted'),
    error: t('toast.error')
  };
  
  const formattedDate = new Date(sessionData.createdAt).toLocaleDateString('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
  
  const sessionDate = new Date(sessionData.date).toLocaleDateString('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setArchiveData({ ...archiveData, [name]: value });
  }

  const saveSession = async() => {
    try {
      if (!user?.result?._id) {
        toast.error(t('toast.error'));
        return;
      }

      const updatedArchiveData = {
        ...sessionData,
        comment,
        userId: user.result._id,
        image1: archiveData.image1,
        image2: archiveData.image2,
        image3: archiveData.image3,
        image4: archiveData.image4
      };
      
      // Create archive
      await dispatch(createArchive({ 
        archiveData: updatedArchiveData, 
        toast 
      }));

      // Delete session
      await dispatch(deleteSession({ 
        id: sessionData._id, 
        toast 
      }));

      // Refresh sessions list
      await dispatch(getUserSessions({ userId: user.result._id }));
      
      // Close popup and show success message
      closePopup();
      toast.success(t('toast.archiveAdded'));
      
    } catch (error) {
      console.error('Error in saveSession:', error);
      toast.error(t('toast.error'));
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className='bg-white rounded-lg p-6 max-w-md w-full mx-4 relative'>
        <button 
          onClick={closePopup}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <ul className='text-sm my-2'>
          <li className="flex flex-row justify-between py-2 border-b">
            <span className="font-bold">{t('clients.name')}:</span>
            <span>{sessionData.firstName} {sessionData.lastName}</span>
          </li>
          <li className="flex flex-row justify-between py-2 border-b">
            <span className="font-bold">{t('clients.phone')}:</span>
            <span>{sessionData.phone}</span>
          </li>
          <li className="flex flex-row justify-between py-2 border-b">
            <span className="font-bold">{t('sessions.date')}:</span>
            <span>{sessionDate}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('sessions.time')}:</span>
            <span>{sessionData.time}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('sessions.service')}:</span>
            <span>{sessionData.service}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('sessions.price')}:</span>
            <span>{sessionData.price}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('sessions.worker')}:</span>
            <span>{sessionData.workerFirstName} {sessionData.workerLastName}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('services.createdBy')}:</span>
            <span>{user?.result?.email}</span>
          </li>
        </ul>

        <div className="flex flex-col m-1 w-full p-1">
          <p className='text-sm font-bold mb-2'>{t('sessions.imagesTitle')}</p>
          <div className="space-y-2">
            <FileBase type="file"
              multiple={false}
              onDone={({ base64 }) => setArchiveData({ ...archiveData, image1: base64 })}
            />
            <FileBase type="file"
              multiple={false}
              onDone={({ base64 }) => setArchiveData({ ...archiveData, image2: base64 })}
            />
            <FileBase type="file"
              multiple={false}
              onDone={({ base64 }) => setArchiveData({ ...archiveData, image3: base64 })}
            />
            <FileBase type="file"
              multiple={false}
              onDone={({ base64 }) => setArchiveData({ ...archiveData, image4: base64 })}
            />
          </div>

          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            placeholder={t('sessions.descriptionPlaceholder')}
            className="mt-4 w-full h-24 border border-gray-300 p-2 rounded-md resize-none focus:ring-2 focus:ring-green-400 focus:outline-none"
          />
        </div>

        <button 
          onClick={saveSession}
          className="mt-4 w-full bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition-colors"
        >
          <span className="font-bold">{sessionData.firstName} {sessionData.lastName}</span> - {t('sessions.completeSession')}
        </button>
      </div>
    </div>
  );
}

export default SessionData;