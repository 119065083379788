import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api.js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const createArchive = createAsyncThunk(
    "archive/createArchive",
    async ({archiveData, toast}, {rejectWithValue}) => {
        try {
            const response = await api.createArchive(archiveData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error creating archive');
        }
    }
);

export const getUserArchives = createAsyncThunk(
    "archive/getUserArchives",
    async ({userId}, {rejectWithValue}) => {
        try {
            const response = await api.getUserArchives(userId);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error getting archives');
        }
    }
);

export const fetchArchiveImages = createAsyncThunk(
    "archive/fetchArchiveImages",
    async (id, { rejectWithValue }) => {
        try {
            console.log('Pozivam API za slike, ID:', id);
            const response = await api.fetchArchiveImages(id);
            console.log('API odgovor za slike:', response);
            // Proveravamo strukturu odgovora
            if (response.data) {
                console.log('Dobijeni podaci:', response.data);
                return response.data;
            } else {
                console.error('Nema data objekta u odgovoru:', response);
                return rejectWithValue('Neispravna struktura odgovora');
            }
        } catch (error) {
            console.error('Greška pri dohvatanju slika:', error);
            return rejectWithValue(error.response?.data || 'Error fetching archive images');
        }
    }
);

export const deleteArchive = createAsyncThunk(
    "archive/deleteArchive",
    async ({id, toast}, {rejectWithValue}) => {
        try {
            const response = await api.deleteArchive(id);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Error deleting archive');
        }
    }
);

export const updateArchive = createAsyncThunk(
    "archive/updateArchive",
    async ({id, updatedArchiveData, navigate, toast},{rejectWithValue})=>{
        try{
            const response = await api.updateArchive(id, updatedArchiveData);
            toast.success(i18next.t('toast.archiveUpdated'));
            navigate('/admin');
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
{/*

export const getClients = createAsyncThunk(
    "clsessionetClient",
    async ({userId},{rejectWithValue})=>{
        try{
            
            const response = await api.getClients(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

*/}
const archiveSlice = createSlice({
    name: "archive",
    initialState: {
        archives: [],
        archiveImages: {},
        error: "",
        loading: false,
    },
    reducers: {
        setArchives: (state, action) => {
            state.archives = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createArchive.pending, (state) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(createArchive.fulfilled, (state, action) => {
                state.loading = false;
                state.archives = [...state.archives, action.payload];
            })
            .addCase(createArchive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getUserArchives.pending, (state) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(getUserArchives.fulfilled, (state, action) => {
                state.loading = false;
                state.archives = action.payload;
            })
            .addCase(getUserArchives.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteArchive.pending, (state) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(deleteArchive.fulfilled, (state, action) => {
                state.loading = false;
                const {
                    arg: { id },
                } = action.meta;
                if (id) {
                    state.archives = state.archives.filter((item) => item._id !== id);
                }
            })
            .addCase(deleteArchive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateArchive.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateArchive.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.archives = state.archives.map((item)=> item._id === id ? action.payload : item)
                }
            })
            .addCase(updateArchive.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message;
            })
            .addCase(fetchArchiveImages.pending, (state) => {
                state.loading = true;
                state.error = "";
            })
            .addCase(fetchArchiveImages.fulfilled, (state, action) => {
                state.loading = false;
                console.log('Primljeni podaci u fulfilled:', action.payload);
                console.log('Meta arg (ID):', action.meta.arg);
                
                // Obradi odgovor sa servera
                // Server vraća niz objekata, a nama treba prvi element
                const imageData = Array.isArray(action.payload) && action.payload.length > 0 
                    ? action.payload[0] 
                    : action.payload;
                
                console.log('Obrađeni podaci za slike:', imageData);
                
                state.archiveImages = {
                    ...state.archiveImages,
                    [action.meta.arg]: imageData
                };
                
                console.log('Novo stanje archiveImages:', state.archiveImages);
            })
            .addCase(fetchArchiveImages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
    }
});

export const { setArchives, setError } = archiveSlice.actions;

export default archiveSlice.reducer;