import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api.js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

export const createWorker = createAsyncThunk(
    "worker/createWorker",
    async ({workerData, navigate, toast}, {rejectWithValue}) =>{
        try {
            const response = await api.createWorker(workerData);
            toast.success(i18next.t('toast.workerAdded'));
            navigate('/admin');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const getUserWorkers = createAsyncThunk(
    "worker/getUserWorkers",
    async ({userId},{rejectWithValue})=>{
        try{
            const response = await api.getUserWorkers(userId);
            return response?.data || { message: "Worker deleted, no response data" };
        }catch(error){
            return rejectWithValue(error.response?.data || { message: "Error occurred" });
        }
    }
)
export const updateWorker = createAsyncThunk(
    "worker/updateWorker",
    async ({id, updatedWorkerData, navigate, toast},{rejectWithValue})=>{
        try{
            console.log("Updating worker with ID:", id);
            console.log("Update data:", updatedWorkerData);
            const response = await api.updateWorker(id, updatedWorkerData);
            console.log("Update response:", response);
            toast.success(i18next.t('toast.workerUpdated'));
            if (navigate) {
                navigate('/admin');
            }
            return response.data;
        }catch(error){
            console.error("Worker update error:", error);
            const errorMsg = error.response?.data?.message || "Error updating worker";
            toast.error(errorMsg);
            return rejectWithValue(error.response?.data || { message: errorMsg });
        }
    }
)
export const deleteOneWorker = createAsyncThunk(
    "worker/deleteOneWorker",
    async ({id, toast},{rejectWithValue})=>{
        try{
            const response = await api.deleteOneWorker(id);
            toast.success(i18next.t('toast.workerDeleted'))
            return response.data;
        }catch(error){
            return rejectWithValue(error.response?.data || { message: "Error deleting worker" });
        }
    }
)


const workerSlice = createSlice({ 
    name:'worker',
    initialState:{
        worker:{},
        workers:[],
        userWorkers:[],
        error:"",
        loading:false
    },
   
    extraReducers: (builder) => {
        builder
            .addCase(createWorker.pending, (state) => {
                state.loading = true;
            })
            .addCase(createWorker.fulfilled, (state, action) => {
                state.loading = false;
                state.workers = [...state.workers, action.payload];
                
            })
            .addCase(createWorker.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to create worker";
            })
            .addCase(getUserWorkers.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserWorkers.fulfilled, (state, action) => {
                state.loading = false;
                state.workers =  action.payload;
                
            })
            .addCase(getUserWorkers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to get workers";
            })
            .addCase(deleteOneWorker.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteOneWorker.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
            if(id){
              state.workers = state.workers.filter((item)=> item._id !== id)
            }
              })
            .addCase(deleteOneWorker.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to delete worker";
            })
            .addCase(updateWorker.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateWorker.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.workers = state.workers.map((item)=> item._id === id ? action.payload : item)
                }
            })
            .addCase(updateWorker.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to update worker"; 
            })
       
    }
});
export default workerSlice.reducer;