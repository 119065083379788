import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchArchiveImages } from '../redux/features/archiveSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faComment } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const ClientFilteredData = ({showClientCard, onClose}) => {
  const archives = useSelector((state) => state.archive?.archives || []);
  const selectedClient = archives.find(item => item._id === showClientCard);
  const [showImages, setShowImages] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const archiveImages = useSelector(state => state.archive.archiveImages);

  // Pratimo kada su slike učitane
  useEffect(() => {
    if (selectedClient && archiveImages[selectedClient._id]) {
      console.log('ClientFilteredData - slike učitane:', archiveImages[selectedClient._id]);
      setIsLoading(false);
    }
  }, [archiveImages, selectedClient]);

  const openShowData = async(id) => {
    setShowImages(!showImages);
    
    // Učitaj slike samo ako nisu već učitane
    if (!archiveImages[id]) {
      setIsLoading(true);
      try {
        await dispatch(fetchArchiveImages(id));
      } catch (error) {
        console.error("Error loading images:", error);
        setIsLoading(false);
      }
    }
  }

  if (!selectedClient) {
    return null;
  }

  const currentArchiveImages = archiveImages[selectedClient._id];

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'>
      <div className='bg-white rounded-lg p-6 max-w-md w-full mx-4 relative'>
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        <ul className='text-sm my-2'>
          <li className="flex flex-row justify-between py-2 border-b">
            <span className="font-bold">{t('clients.name')}:</span>
            <span>{selectedClient.firstName} {selectedClient.lastName}</span>
          </li>
          <li className="flex flex-row justify-between py-2 border-b">
            <span className="font-bold">{t('clients.phone')}:</span>
            <span>{selectedClient.phone}</span>
          </li>
          <li className="flex flex-row justify-between py-2 border-b">
            <span className="font-bold">{t('sessions.date')}:</span>
            <span>{new Date(selectedClient.date).toLocaleDateString('sr-RS', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('sessions.time')}:</span>
            <span>{selectedClient.time}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('sessions.service')}:</span>
            <span>{selectedClient.service}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('sessions.price')}:</span>
            <span>{selectedClient.price}</span>
          </li>
          <li className='flex flex-row justify-between py-2 border-b'>
            <span className="font-bold">{t('sessions.worker')}:</span>
            <span>{selectedClient.workerFirstName} {selectedClient.workerLastName}</span>
          </li>
          <li className='py-2 border-b'>
            <div className="flex items-center mb-2">
              <FontAwesomeIcon icon={faComment} className="mr-2 text-green-600" />
              <span className="font-bold">{t('sessions.comment')}:</span>
            </div>
            <div className="bg-gray-50 p-3 rounded-md text-gray-700 whitespace-pre-wrap">
              {selectedClient?.comment || ''}
            </div>
          </li>
        </ul>

        <button
          onClick={() => openShowData(selectedClient._id)}
          className="w-full mt-4 bg-green-600 text-white py-2 px-4 rounded hover:bg-green-700 transition-colors"
        >
          {showImages ? t('sessions.hideImages') : t('sessions.viewImages')}
        </button>

        {showImages && (
          <div className="mt-4">
            {isLoading ? (
              <div className="flex justify-center items-center my-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
              </div>
            ) : currentArchiveImages ? (
              <div className="grid grid-cols-2 gap-2">
                {currentArchiveImages.image1 && (
                  <img src={currentArchiveImages.image1} className='w-full h-32 object-cover rounded' alt="Image 1" />
                )}
                {currentArchiveImages.image2 && (
                  <img src={currentArchiveImages.image2} className='w-full h-32 object-cover rounded' alt="Image 2" />
                )}
                {currentArchiveImages.image3 && (
                  <img src={currentArchiveImages.image3} className='w-full h-32 object-cover rounded' alt="Image 3" />
                )}
                {currentArchiveImages.image4 && (
                  <img src={currentArchiveImages.image4} className='w-full h-32 object-cover rounded' alt="Image 4" />
                )}
                {(!currentArchiveImages.image1 && !currentArchiveImages.image2 && !currentArchiveImages.image3 && !currentArchiveImages.image4) && (
                  <div className="col-span-2 text-center text-gray-500 py-4">
                    {t('common.noImages')}
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center text-gray-500 py-4">
                {t('common.noImages')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ClientFilteredData;