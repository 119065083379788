import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { register, selectLoading, selectError } from '../redux/features/authSlice';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';

const initialState = {
  company:'',
  person:'',
  email:'',
  password:'',
  confirm:'',
  country:'',
  city:'',
  address:'',
  phone:'', 
  mailpassword:''
  }

const Register = () => {
  const { t } = useTranslation();
  const [formValue, setFormValue] = useState(initialState);
  const {company, person, email, confirm, password, country, city, address, phone, mailpassword} = formValue;
  const [countryCode, setCountryCode] = useState('');
  const [verify, setVerify] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

useEffect(()=>{
 error&&toast.error(error)
},[error])
const getUserCountryCode = async () => {
        try {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          setCountryCode(data.country_code.toLowerCase()); 
        } catch (error) {
        }
      };
      useEffect(() => {
        getUserCountryCode();
      }, []);
  const handleSubmit = (e) =>{
 e.preventDefault();
 if (!phone) {
  toast.error(t('auth.phoneRequired'));
  return;
}
if(password !== confirm){
  return toast.error(t('auth.passwordMismatch'))
}
if(company && person && email && password && city && address&&phone){
  const updatedFormValue = {...formValue, mailpassword: formValue.mailpassword || ""}
  console.log(updatedFormValue, mailpassword);
  
  dispatch(register({formValue: updatedFormValue, navigate, toast}));
  setVerify(true);
}
 }
  const onInputChange = (e) =>{
 let {name, value} = e.target;
 setFormValue({...formValue,[name]:value});
  }

  return (
    <div className="min-h-screen flex flex-col">
    <Header />

    {/* Main Content */}
    <div className="flex-grow pt-20 flex items-center justify-center bg-gradient-to-br from-green-900  via-green-500 to-green-200">
      <div className="bg-white p-8 shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold text-center text-gray-800">{t('auth.signUpTitle')}</h2>
       {!verify && <form className="mt-4 space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-700">{t('auth.company')}</label>
            <input id="company" name="company" type="text" required onChange={onInputChange} value={company}
              placeholder={t('auth.companyPlaceholder')}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="person" className="block text-sm font-medium text-gray-700">{t('auth.fullName')}</label>
            <input id="person" name="person" type="text" required onChange={onInputChange} value={person}
              placeholder={t('auth.personPlaceholder')}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">{t('auth.email')}</label>
            <input id="email" name="email" type="email" required onChange={onInputChange} value={email}
              placeholder={t('auth.emailPlaceholder')}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="client" className="block text-sm font-medium text-gray-700">{t('auth.phone')}</label>
            
            <PhoneInput
        country={countryCode}
        enableSearch={true}
        value={phone}
        onChange={(value) => setFormValue({ ...formValue, phone: `+${value }`})}
        inputStyle={{
            width: '100%',
            padding: '12px',
            borderRadius: '7px',
            border: '1px solid #ccc',
            paddingLeft:'50px',
            height:'32px'
          }}
          placeholder={t('auth.phonePlaceholder')}
      />
       <label htmlFor="city" className="block text-sm font-medium text-gray-700">{t('auth.city')}</label>
            <input id="city" name="city" type="text" required onChange={onInputChange} value={city}
              placeholder={t('auth.cityPlaceholder')}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="address" className="block text-sm font-medium text-gray-700">{t('auth.address')}</label>
            <input id="address" name="address" type="text" required onChange={onInputChange} value={address}
              placeholder={t('auth.addressPlaceholder')}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">{t('auth.password')}</label>
            <input id="password" name="password" type="password" required onChange={onInputChange} value={password}
              placeholder={t('auth.passwordPlaceholder')}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
            <label htmlFor="confirm" className="block text-sm font-medium text-gray-700">{t('auth.confirmPassword')}</label>
            <input id="confirm" name="confirm" type="password" required onChange={onInputChange} value={confirm}
              placeholder={t('auth.passwordPlaceholder')}
              className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
         <label htmlFor="remember_me" className="m-2 block text-sm text-gray-900">
              {t('auth.hasAccount')} <Link to="/login" className="text-green-700 underline">{t('auth.signIn')}</Link>
            </label>
          </div>

          <div>
            <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 transition-colors duration-200">
              {t('auth.signUp')}
            </button>
          </div>
        </form>}
        {verify && 
        <div className='flex flex-col'>
          <p>{t('auth.verificationEmailSent')}</p>
          <button onClick={()=>setVerify(false)}  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">{t('auth.sendAgain')}</button>
        </div>
        }
      </div>
    </div>
  </div>
  )
}

export default Register