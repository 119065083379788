import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';

const ColorDropdown = ({ initialColor, forwardColor }) => {
  const { t } = useTranslation();
  const [selectedColor, setSelectedColor] = useState(initialColor || "darkgray");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (initialColor && initialColor !== selectedColor) {
      setSelectedColor(initialColor);
    }
  }, [initialColor]);

  const colors = [
    "red",
    "blue",
    "green",
    "yellow",
    "purple",
    "orange",
    "pink",
    "brown",
    "gray",
    "black",
    "white",
    "cyan",
    "magenta",
    "lime",
    "maroon",
    "navy",
    "olive",
    "teal",
    "aqua",
    "fuchsia",
    "silver",
    "darkgray"
  ];

  const setColor = (color) => {
    setSelectedColor(color);
    forwardColor(color);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-3 py-2 border rounded flex items-center justify-between"
      >
        <div
          className="w-6 h-6 md:w-8 md:h-8 rounded-full"
          style={{ backgroundColor: selectedColor }}
        />
        <span className="ml-2">{selectedColor}</span>
      </button>

      {isOpen && (
        <div className="absolute z-10 mt-1 w-full bg-white border rounded-md shadow-lg">
          <div className="grid grid-cols-4 gap-1 p-2">
            {colors.map((color) => (
              <button
                key={color}
                type="button"
                onClick={() => setColor(color)}
                className={`w-full aspect-square rounded-full hover:ring-2 hover:ring-offset-2 hover:ring-green-500 ${color === selectedColor ? 'ring-2 ring-offset-2 ring-green-500' : ''}`}
                style={{
                  backgroundColor: color,
                  width: 'clamp(2rem, 4vw, 3rem)',
                  height: 'clamp(2rem, 4vw, 3rem)'
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorDropdown;
