import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api.js';
import { useTranslation } from 'react-i18next';

export const createClient = createAsyncThunk(
    "client/createClient",
    async ({updatedClientData}, {dispatch}) => {
        try {
            const response = await api.createClient(updatedClientData);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);

{/*export const getClients = createAsyncThunk(
    "client/getClient",
    async (_,{rejectWithValue})=>{
        try{
            const response = await api.getClients();
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)*/}
export const getClients = createAsyncThunk(
    "client/getClient",
    async ({userId},{rejectWithValue})=>{
        try{
            const response = await api.getClients(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
export const deleteClient = createAsyncThunk(
    "client/deleteClient",
    async ({id, toast},{rejectWithValue})=>{
        try{
            const response = await api.deleteClient(id);
            toast.success('clientDeleted')
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
export const updateClient = createAsyncThunk(
    "client/updateClient",
    async ({id, updatedClientData, navigate, toast},{rejectWithValue})=>{
        try{
            const response = await api.updateClient(id, updatedClientData);
            toast.success('clientUpdated');
            navigate('/admin');
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

const clientSlice = createSlice({
    name:'client',
    initialState:{
        client:{},
        clients:[],
        userClients:[],
        error:"",
        loading:false
    },
    reducers: {
        resetError(state) {
          state.error = null;
        },
      },
    extraReducers: (builder) => {
        builder
            .addCase(createClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(createClient.fulfilled, (state, action) => {
                state.loading = false;
                state.clients = [...state.clients, action.payload];
                
            })
            .addCase(createClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
            .addCase(getClients.pending, (state) => {
                state.loading = true;
            })
            .addCase(getClients.fulfilled, (state, action) => {
                state.loading = false;
                state.clients =  action.payload;
                
            })
            .addCase(getClients.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
            .addCase(deleteClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteClient.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.clients = state.clients.filter((item)=> item._id !== id)
                }
            })
            .addCase(deleteClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })
            .addCase(updateClient.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateClient.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.clients = state.clients.map((item)=> item._id === id ? action.payload : item)
                }
            })
            .addCase(updateClient.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })
    }
});
export default clientSlice.reducer;