import React, {useState, useEffect} from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { logout, selectUser } from '../redux/features/authSlice';
import { jwtDecode } from 'jwt-decode';
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useTranslation } from 'react-i18next';

const HeaderAdmin = React.memo(() => {
    const [show, setShow] = useState(false);
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const location = useLocation();

    const { t, i18n } = useTranslation(); 

    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = user?.token;
    if(token){
        const decodedToken = jwtDecode(token);
        if(decodedToken.exp * 1000< new Date().getTime()){
            dispatch(logout());
        }
    }
    useEffect(()=>{
        if (user.result?.phone) {
            setPhone(user.result.phone);
            const phoneNumber = parsePhoneNumberFromString(user.result.phone);
            if (phoneNumber) {
                const countryCode = phoneNumber.country;
                setCountry(countryCode);
            }
        }
    })

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleLogout = () =>{
        dispatch(logout());
        navigate('/');
    }

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    }

    const isActive = (path) => {
        return location.pathname === path;
    }
    
    return (
        <header className="bg-gradient-to-r from-green-900 via-green-500 to-green-700">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <div className="flex-shrink-0 flex items-center">
                            <Link to="/dashboard" className="bg-black py-1 px-2  text-xl font-bold text-white hover:text-green-100 transition-colors duration-200">
                                {t('common.appName')}
                            </Link>
                        </div>
                        {/* Desktop Navigation */}
                        <div className="hidden sm:ml-6 sm:flex">
                            <Link
                                to="/admin"
                                className={`inline-flex items-center px-4 py-2 text-sm font-medium transition-colors duration-200 ${
                                    isActive('/admin') 
                                        ? 'bg-black text-white' 
                                        : 'text-gray-300 hover:bg-green-800/50 hover:text-white'
                                }`}
                            >
                                {t('common.dashboard')}
                            </Link>
                            <Link
                                to="/packages"
                                className={`inline-flex items-center px-4 py-2 text-sm font-medium transition-colors duration-200 ${
                                    isActive('/packages') 
                                        ? 'bg-black text-white' 
                                        : 'text-gray-300 hover:bg-green-800/50 hover:text-white'
                                }`}
                            >
                                {t('common.packages')}
                            </Link>
                            <Link
                                to="/profile"
                                className={`inline-flex items-center px-4 py-2 text-sm font-medium transition-colors duration-200 ${
                                    isActive('/profile') 
                                        ? 'bg-black text-white' 
                                        : 'text-gray-300 hover:bg-green-800/50 hover:text-white'
                                }`}
                            >
                                {t('common.profile')}
                            </Link>
                            <Link
                                to="/billing"
                                className={`inline-flex items-center px-4 py-2 text-sm font-medium transition-colors duration-200 ${
                                    isActive('/billing') 
                                        ? 'bg-black text-white' 
                                        : 'text-gray-300 hover:bg-green-800/50 hover:text-white'
                                }`}
                            >
                                {t('common.billing')}
                            </Link>
                        </div>
                    </div>

                    {/* Desktop Right Side */}
                    <div className="flex items-center space-x-4">
                        <div className="hidden sm:flex space-x-2">
                            <button
                                onClick={() => changeLanguage('en')}
                                className={`px-2 py-1 rounded font-semibold ${i18n.language === 'en' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white'}`}
                                title={t('common.languages.english')}
                            >
                                EN
                            </button>
                            <button
                                onClick={() => changeLanguage('rs')}
                                className={`px-2 py-1 rounded font-semibold ${i18n.language === 'rs' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white'}`}
                                title={t('common.languages.serbian')}
                            >
                                RS
                            </button>
                            <button
                                onClick={() => changeLanguage('fr')}
                                className={`px-2 py-1 rounded font-semibold ${i18n.language === 'fr' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white'}`}
                                title={t('common.languages.french')}
                            >
                                FR
                            </button>
                            <button
                                onClick={() => changeLanguage('it')}
                                className={`px-2 py-1 rounded font-semibold ${i18n.language === 'it' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white'}`}
                                title={t('common.languages.italian')}
                            >
                                IT
                            </button>
                            <button
                                onClick={() => changeLanguage('es')}
                                className={`px-2 py-1 rounded font-semibold ${i18n.language === 'es' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white'}`}
                                title={t('common.languages.spanish')}
                            >
                                ES
                            </button>
                        </div>
                        <span className="hidden sm:inline text-gray-300 font-medium">{user?.email}</span>
                        <button
                            onClick={handleLogout}
                            className="hidden sm:inline text-gray-300 hover:text-white font-medium transition-colors duration-200"
                        >
                            {t('auth.logout')}
                        </button>
                    </div>

                    {/* Mobile menu button */}
                    <div className="flex items-center sm:hidden">
                        <button
                            onClick={toggleMobileMenu}
                            className="inline-flex items-center justify-center p-2 rounded-md text-white hover:bg-green-800/50 focus:outline-none transition-colors duration-200"
                            aria-label={t('common.menu')}
                        >
                            <span className="sr-only">{t('common.menu')}</span>
                            {isMobileMenuOpen ? (
                                <FontAwesomeIcon icon={faTimes} className="block h-6 w-6 font-bold" />
                            ) : (
                                <FontAwesomeIcon icon={faBars} className="block h-6 w-6 font-bold" />
                            )}
                        </button>
                    </div>
                </div>
            </nav>

            {/* Mobile menu */}
            {isMobileMenuOpen && (
                <div className="sm:hidden bg-green-900">
                    <div className="pt-2 pb-3">
                        <Link
                            to="/admin"
                            className={`block px-4 py-2 text-base font-medium transition-colors duration-200 ${
                                isActive('/admin') 
                                    ? 'bg-black text-white' 
                                    : 'text-gray-300 hover:text-white hover:bg-green-800/50'
                            }`}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            {t('common.dashboard')}
                        </Link>
                        <Link
                            to="/packages"
                            className={`block px-4 py-2 text-base font-medium transition-colors duration-200 ${
                                isActive('/packages') 
                                    ? 'bg-black text-white' 
                                    : 'text-gray-300 hover:text-white hover:bg-green-800/50'
                            }`}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            {t('common.packages')}
                        </Link>
                        <Link
                            to="/profile"
                            className={`block px-4 py-2 text-base font-medium transition-colors duration-200 ${
                                isActive('/profile') 
                                    ? 'bg-black text-white' 
                                    : 'text-gray-300 hover:text-white hover:bg-green-800/50'
                            }`}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            {t('common.profile')}
                        </Link>
                        <Link
                            to="/billing"
                            className={`block px-4 py-2 text-base font-medium transition-colors duration-200 ${
                                isActive('/billing') 
                                    ? 'bg-black text-white' 
                                    : 'text-gray-300 hover:text-white hover:bg-green-800/50'
                            }`}
                            onClick={() => setIsMobileMenuOpen(false)}
                        >
                            {t('common.billing')}
                        </Link>
                        <div className="px-4 py-2">
                            <div className="flex flex-wrap gap-2">
                                <button
                                    onClick={() => {
                                        changeLanguage('en');
                                        setIsMobileMenuOpen(false);
                                    }}
                                    className={`px-3 py-1 rounded font-semibold ${i18n.language === 'en' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white hover:bg-green-800/50'}`}
                                >
                                    EN
                                </button>
                                <button
                                    onClick={() => {
                                        changeLanguage('rs');
                                        setIsMobileMenuOpen(false);
                                    }}
                                    className={`px-3 py-1 rounded font-semibold ${i18n.language === 'rs' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white hover:bg-green-800/50'}`}
                                >
                                    RS
                                </button>
                                <button
                                    onClick={() => {
                                        changeLanguage('fr');
                                        setIsMobileMenuOpen(false);
                                    }}
                                    className={`px-3 py-1 rounded font-semibold ${i18n.language === 'fr' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white hover:bg-green-800/50'}`}
                                >
                                    FR
                                </button>
                                <button
                                    onClick={() => {
                                        changeLanguage('it');
                                        setIsMobileMenuOpen(false);
                                    }}
                                    className={`px-3 py-1 rounded font-semibold ${i18n.language === 'it' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white hover:bg-green-800/50'}`}
                                >
                                    IT
                                </button>
                                <button
                                    onClick={() => {
                                        changeLanguage('es');
                                        setIsMobileMenuOpen(false);
                                    }}
                                    className={`px-3 py-1 rounded font-semibold ${i18n.language === 'es' ? 'bg-white text-green-900' : 'text-gray-300 hover:text-white hover:bg-green-800/50'}`}
                                >
                                    ES
                                </button>
                            </div>
                        </div>
                        <div className="px-4 py-2">
                            <span className="text-gray-300 font-medium">{user?.email}</span>
                        </div>
                        <div className="px-4 py-2">
                            <button
                                onClick={() => {
                                    handleLogout();
                                    setIsMobileMenuOpen(false);
                                }}
                                className="text-gray-300 hover:text-white font-medium transition-colors duration-200"
                            >
                                {t('auth.logout')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </header>
    )
})

export default HeaderAdmin