import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../redux/features/authSlice';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createService, getUserServices } from '../redux/features/serviceSlice';
import PriceInput from '../components/PriceInput';
import ColorDropdown from '../components/ColorDropdown';
import { createCategory, getUserCategories } from '../redux/features/categorySlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Services from '../lists/Services';
import Categories from '../lists/Categories';
import { useTranslation } from 'react-i18next';

const initialState = {
  service: '',
  price: '',
  duration: '',
  category: ''
}

const AddService = ({goToAddSession, categories, services})=> {
  const user = useSelector(selectUser);
  const userId = user?.result._id;
  const [category, setCategory] = useState('');
  const [serviceData, setServiceData] = useState(initialState);
  const [colorFinish, setColorFinish] = useState('darkgray');
  const {service, price, duration} = serviceData;
  const [categoryView, setCategoryView] = useState(false);
  const [addNewView, setNewView] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showData, setShowData] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Fetch categories and services on component mount if needed or when refresh is triggered
  useEffect(() => {
    if (userId) {
      dispatch(getUserCategories({userId}));
      dispatch(getUserServices({userId}));
    }
  }, [userId, dispatch, refreshTrigger]);

  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const setPrice = (price) => {
    setServiceData({...serviceData, price})
  }

  const onCategoryChange = (e) => {
    const {value} = e.target;
    setCategory(value);
  };

  const onInputChange = (e) => {
    const {name, value} = e.target;
    setServiceData({...serviceData, [name]: value})
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!service || service.trim() === '') {
      toast.error(t('services.serviceNameRequired'));
      return;
    }
    
    try {
      if (addNewView && category) {
        // Create new category with service
        const categoryData = {
          category: category,
          colorFinish: colorFinish
        };
        
        // First create the category
        dispatch(createCategory({categoryData, navigate, toast}))
          .then((response) => {
            // Get the created category data from the response
            if (response.payload) {
              const createdCategoryId = response.payload._id;
              
              // Now create the service with the new category reference
              const updatedServiceData = {
                ...serviceData,
                category: category,
                categoryId: createdCategoryId,
                colorFinish: colorFinish
              };
              
              dispatch(createService({updatedServiceData, navigate, toast}))
                .then(() => {
                  setRefreshTrigger(prev => prev + 1);
                  handleClear();
                  toast.success(t('toast.serviceAndCategoryAdded'));
                });
            }
          });
      } else if (selectedCategory) {
        // Use existing category
        const selectedCategoryObj = categories.find(cat => cat.category === selectedCategory);
        
        const updatedServiceData = {
          ...serviceData,
          category: selectedCategory,
          categoryId: selectedCategoryObj?._id, // Include the category ID reference
          colorFinish: selectedCategoryObj?.colorFinish || 'darkgray'
        };
        
        dispatch(createService({updatedServiceData, navigate, toast}))
          .then(() => {
            setRefreshTrigger(prev => prev + 1);
            handleClear();
            toast.success(t('toast.serviceAdded'));
          });
      } else {
        toast.error(t('services.selectCategory'));
        return;
      }
    } catch (error) {
      console.error("Error creating service:", error);
      toast.error(t('toast.error'));
    }
  }

  const handleCategory = (e) => {
    e.preventDefault();
    
    if (!category || category.trim() === '') {
      toast.error(t('services.categoryNameRequired'));
      return;
    }
    
    try {
      const categoryData = {
        category: category,
        colorFinish: colorFinish
      };
      
      dispatch(createCategory({categoryData, navigate, toast}))
        .then(() => {
          setRefreshTrigger(prev => prev + 1);
          setCategory(''); // Clear the field after submission
          toast.success(t('toast.categoryAdded'));
        });
    } catch (error) {
      console.error("Error creating category:", error);
      toast.error(t('toast.error'));
    }
  }

  const handleClear = () => {
    setServiceData({service: '', price: '', duration: ''});
    setSelectedCategory('');
    setCategory('');
    setNewView(false);
  }

  const takeColor = (color) => {
    setColorFinish(color);
  }

  const addNew = () => {
    setNewView(true);
    setSelectedCategory('');
  }

  return (
    <div>
      <div className="flex flex-row justify-between md:justify-around">
        <h2 
          type="button" 
          onClick={() => setCategoryView(false)} 
          className={`text-xl font-bold text-white mb-1 ${!categoryView ? 'bg-green-900 rounded-md p-1' : ''}`}
        >
          {t('services.addService')}
        </h2>
        <h2 
          type="button" 
          onClick={() => setCategoryView(true)} 
          className={`text-xl font-bold text-white mb-1 ${categoryView ? 'bg-green-900 rounded-md p-1' : ''}`}
        >
          {t('services.category')}
        </h2>
      </div>
      
      {categoryView && 
        <div>
          <form onSubmit={handleCategory} className="space-y-6 w-full">
            <div className="flex md:flex-row flex-col w-full">
              <div className='md:w-1/2 w-full'>
                <label htmlFor="category" className="block text-sm font-medium text-gray-100">{t('services.category')}</label>
                <input 
                  onChange={onCategoryChange} 
                  value={category}
                  id="category" 
                  name="category" 
                  type="text" 
                  className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" 
                />
              </div>
              <div className='md:w-1/2 w-full'>
                <label htmlFor="categoryColor" className="block text-sm font-medium text-gray-100">{t('services.chooseColorOfCategory')}</label>
                <ColorDropdown forwardColor={takeColor} initialColor={colorFinish} />
              </div>
            </div>
            <button 
              type="submit" 
              className="md:w-1/2 w-full flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
            >
              {t('services.submit')}
            </button>
          </form>
          
          <h5 className='text-white font-bold mt-4'>{t('services.allCategories')}</h5>
          {categories && categories.length > 0 ? (
            <Categories categories={categories} />
          ) : (
            <p className="text-white">No categories available</p>
          )}
        </div>
      }
      
      {!categoryView && 
        <div>
          <form onSubmit={handleSubmit} className="space-y-6 w-full">
            <div className="flex md:flex-col flex-col w-full justify-center">
              <div className='md:w-1/2 w-full'>
                <label htmlFor="service" className="block text-sm font-medium text-gray-100">{t('services.kindOfService')}</label>
                <input 
                  onChange={onInputChange} 
                  value={service}
                  id="service" 
                  name="service" 
                  type="text" 
                  className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" 
                />
              </div>
              
              <div className='md:w-1/2 w-full'>
                <label htmlFor="Choose" className="block text-sm font-medium text-gray-100">{t('services.serviceCategory')}</label>
                {!addNewView && 
                  <select
                    value={selectedCategory}
                    onChange={handleSelectChange}
                    className="w-full px-2 py-1 rounded-md text-center shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
                  >
                    <option value="" className="bg-black text-white">{t('services.chooseACategory')}</option>
                    {categories && categories.map((item) => (
                      <option 
                        style={{color: 'white', backgroundColor: item.colorFinish}} 
                        key={item._id} 
                        value={item.category}
                      >
                        {item.category}
                      </option>
                    ))}
                  </select>
                }
                
                {!addNewView && 
                  <p 
                    onClick={addNew} 
                    className="mt-2 border border-black w-full px-2 py-1 font-bold rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none cursor-pointer"
                  >
                    {t('services.orAddNewCategory')} +
                  </p>
                }
                
                {addNewView && 
                  <div className="flex md:flex-row flex-col w-full">
                    <div className='md:w-1/2 w-full'>
                      <label htmlFor="newCategory" className="block text-sm font-medium text-gray-100">{t('services.addNewCategory')}</label>
                      <input 
                        onChange={onCategoryChange} 
                        value={category}
                        id="newCategory" 
                        name="category" 
                        type="text" 
                        className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" 
                      />
                    </div>
                    <div className='md:w-1/2 w-full'>
                      <label htmlFor="categoryColor" className="block text-sm font-medium text-gray-100">{t('services.chooseColorOfCategory')}</label>
                      <ColorDropdown forwardColor={takeColor} initialColor={colorFinish} />
                    </div>
                  </div>
                }
              </div>
              
              <div className='md:w-1/2 w-full'>
                <label htmlFor="price" className="block text-sm font-medium text-gray-100">{t('services.price')}</label>
                <PriceInput sendPrice={setPrice} initialValue={price} />
              </div>
              
              <div className='md:w-1/2 w-full'>
                <label htmlFor="duration" className="block text-sm font-medium text-gray-100">{t('services.timeInMinutes')}</label>
                <input 
                  onChange={onInputChange} 
                  value={duration}
                  id="duration" 
                  name="duration" 
                  type="text" 
                  className="mt-1 w-full px-2 py-1 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" 
                />
              </div>
            </div>
            
            <button 
              type="submit" 
              className="md:w-1/2 w-full flex justify-center py-2 px-2 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
            >
              {t('services.submit')}
            </button>
          </form>
          
          <h5 className='text-white font-bold mt-4'>{t('services.allServices')}</h5>
          {services && services.length > 0 ? (
            <Services services={services} />
          ) : (
            <p className="text-white">No services available</p>
          )}
        </div>
      }
    </div>
  )
}

export default AddService;