import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { faEdit, faTrash, faUser, faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import SessionData from './SessionData';
import { deleteSession } from '../redux/features/sessionSlice';
import { useTranslation } from 'react-i18next';

const Sessions = ({ goToAddSession, sessions, goToClientsCard, workers}) => {
  console.log('sessions');
  console.log(workers);
  
  const formattedDate = new Date(sessions.date).toLocaleDateString('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [showData, setShowData] = useState(false);
  const [sessionData, setSessionData] = useState();
  
  const [selectedDate, setSelectedDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; 
  });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleDelete = (id) =>{
    if(window.confirm(t('sessions.deleteConfirm'))){
        dispatch(deleteSession({id, toast}))
    }
  }
  const openShowData = (id)=>{
    const selectedSession = sessions.find(session => session._id === id);
    setSessionData(selectedSession); 
    setShowData(true); 
  }
 {/* sessions.map((session) => console.log(session.date, selectedDate )
  )*/}
  const filteredData = sessions.filter(session =>
    (session.firstName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (session.lastName?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (session.email?.toLowerCase() || '').includes(searchTerm.toLowerCase()) ||
    (session.phone || '').includes(searchTerm)
  );
    const handleDateChange = (e) => {
      setSelectedDate(e.target.value);
    };
    const calculateEndTime = (startTime, duration) => {
      const [hours, minutes] = startTime.split(":").map(Number);
    
      // Kreiraj novi datum i postavi početno vreme
      const startDate = new Date();
      startDate.setHours(hours, minutes, 0, 0);
    
      // Dodaj trajanje u minutama
      const endDate = new Date(startDate.getTime() + duration * 60000);
    
      // Formatiraj krajnje vreme
      const endHours = endDate.getHours().toString().padStart(2, '0');
      const endMinutes = endDate.getMinutes().toString().padStart(2, '0');
    
      return `${endHours}:${endMinutes}`;
    };
    const goToCard = (phone)=>{
      console.log(phone);
      
      goToClientsCard(phone)
    }
  return (
    <div className="">
      <div className="flex flex-row">
      <h2 className="text-xl font-bold text-white mb-1">{t('sessions.title')}</h2>
      <div className="relative ml-2">
  
          <input
            type="text"
            placeholder={t('clients.searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-1 pl-8 rounded-lg bg-white text-xs text-green-800 border border-green-300 w-[150px]"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-green-700"
          />
        </div>
      </div>
      <div id="calendar" className='bg-green-50 rounded-sm p-1'>
        <div className="flex flex-col">
          <div className="flex flex-row w-full">
          <button
    onClick={() => {
      const prevDate = new Date(selectedDate);
      prevDate.setDate(prevDate.getDate() - 1);
      setSelectedDate(prevDate.toISOString().split('T')[0]);
    }}
    className="px-4 py-1 text-white bg-green-700 hover:bg-green-800 border-r-2"
  >
    &lt; 
  </button>
      <input 
        type="date" 
        id="date" 
        value={selectedDate} 
        onChange={handleDateChange} 
        className="w-full bg-green-700 text-center text-white focus:ring-2 font-bold focus:ring-green-400 focus:outline-none"
      />
        <button
    onClick={() => {
      const nextDate = new Date(selectedDate);
      nextDate.setDate(nextDate.getDate() + 1);
      setSelectedDate(nextDate.toISOString().split('T')[0]);
    }}
    className="px-4 py-1 text-white bg-green-700 hover:bg-green-800 border-l-2"
  >
    &gt; 
  </button>
          </div>
      
       <table className="min-w-full bg-transparent rounded-lg shadow-lg text-black">
          <thead>
            <tr className="bg-transparent text-black">
            </tr>
          </thead>
          <tbody >
          {!searchTerm && sessions.length > 0 ? (
                sessions
                  .slice()
                  .filter((item) => item.date === selectedDate)
                  .sort((a, b) => {
                    const timeA = new Date(`1970-01-01T${a.time}:00`).getTime();
                    const timeB = new Date(`1970-01-01T${b.time}:00`).getTime();
                    return timeA - timeB;
                  })
                  .map((item, index) => {
                    const totalTime = calculateEndTime(item.time, item.duration);

                    // Pronađi boju za ikonu iz workers kolekcije
                    const worker = workers.find(
                      (worker) => worker.firstName === item.workerFirstName || worker.lastName === item.workerLastName
                    );
                    const iconColor = worker ? worker.color : 'gray'; // Ako nema boje, koristi sivo


                    return (
                      <React.Fragment key={index}>
                        <tr className="text-sm bg-green-300">
                          <td className="text-center text-black p-1 text-sm font-bold">{item.time}</td>
                          <td
                            className="text-center text-black p-1 text-sm font-bold cursor-pointer"
                            onClick={() => {
                              goToCard(item.phone);
                            }}
                          >
                            {item.firstName} {item.lastName}
                          </td>
                          <td>
                            <FontAwesomeIcon icon={faUser} style={{ color: iconColor }} />
                          </td>
                        </tr>

                        <tr className="bg-green-100 border border-b-green-800">
                          <td className="text-red-700 text-xs font-bold text-center">{totalTime}</td>
                          <td>
                            <div className="">
                              <button
                                className="text-sm hover:text-base px-3 text-center w-full text-white hover:text-green-900"
                                onClick={() => openShowData(item._id)}
                              >
                                <button className="text-sm text-black hover:text-base hover:text-green-900">
                                  <Link to={`/editSession/${item._id}`}>
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Link>
                                </button>
                                <FontAwesomeIcon className="text-black px-2" icon={faBars} />
                              </button>
                            </div>
                          </td>
                          <td>
                            <FontAwesomeIcon
                              className="text-red-700 text-xs"
                              icon={faTrash}
                              onClick={() => handleDelete(item._id)}
                            />
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
              ) : (
                <tr>
                  <td colSpan="4" className="text-center px-1 py-2 text-white"></td>
                </tr>
              )}

          {searchTerm && filteredData.length > 0 ? (
              filteredData
                .slice()
                .sort((a, b) => a.lastName.localeCompare(b.lastName))
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <tr className=" text-sm border border-white bg-green-200">
                      <td className="py-1 text-black text-xs font-bold">{item.time}</td>
                      <td className="py-1 text-black text-xs font-bold">{item.firstName}{item.lastName}</td>
                      {/*<td className="px-1 py-1">
                        <div className="flex flex-row space-x-4">
                          <button className="text-sm hover:text-base hover:text-green-900">
                            <Link to={`/editClient/${item._id}`}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Link>
                          </button>
                          <button className="text-sm hover:text-base hover:text-green-900" onClick={() => handleDelete(item._id)}>
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button className="text-sm hover:text-base hover:text-green-900" onClick={() => openShowData(item._id)}>
                            <i>View..</i>
                          </button>
                        </div>
                      </td>*/}
                    </tr>
                    <tr>
                    </tr>
                  </React.Fragment>
                ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center px-1 py-2 text-white">
                </td>
              </tr>
            )}
          </tbody>
        </table>
<button onClick={()=>goToAddSession()} className='w-ful bg-green-700 p-1  text-white font-bold text-xs '>{t('sessions.addNewSession')}</button>
        </div>
      </div>
      {showData && (
<SessionData sessionData={sessionData} closePopup={() => setShowData(false)}/>
)}
    </div>
  );
};

export default Sessions;
