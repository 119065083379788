import axios from 'axios';

const devEnv = process.env.NODE_ENV !== "production";

const {REACT_APP_DEV_API, REACT_APP_PROD_API } = process.env;

const API = axios.create({baseURL:`${devEnv?REACT_APP_DEV_API:REACT_APP_PROD_API}`});

API.interceptors.request.use((req) => {
    if (localStorage.getItem("Profile")) {
      req.headers.Authorization = `Bearer ${
        JSON.parse(localStorage.getItem("Profile")).token
      }`;
    }
    return req;
});

API.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem("Profile");
            window.location.href = "/login";
        }
        return Promise.reject(error);
    }
);

export const signin = (formData) => API.post("/users/signin", formData);
export const signup = (formData) => API.post("/users/signup", formData);
export const verifyToken = () => API.get("/users/verify-token");
export const updateProfile = (formData) =>{
  console.log("Podaci koji se šalju na backend:", formData);
    return  API.post("/users/updateProfile", formData)}

{/*export const getClients = () => API.get("/clients");*/} 
//Client API
export const createClient = (clientData) => API.post("/clients", clientData);
export const getClients = (userId) => API.get(`/clients/user/${userId}`);
export const deleteClient = (id) => API.delete(`/clients/${id}`);
export const updateClient = (id, updatedClientData) => API.patch(`/clients/${id}`, updatedClientData);
//Worker API
export const createWorker = (workerData) => API.post("/workers", workerData);
export const getUserWorkers = (userId) => API.get(`/workers/user/${userId}`, userId);
export const deleteOneWorker = (id) => API.delete(`/workers/${id}`);
export const updateWorker = (id, updatedWorkerData) => API.patch(`/workers/${id}`, updatedWorkerData);
//Service API
export const createService = (updatedServiceData) => API.post("/services", updatedServiceData);
export const getUserServices = (userId) => API.get(`/services/user/${userId}`, userId);
export const deleteOneService = (id) => API.delete(`/services/${id}`);
export const updateService = (id, updatedServiceData) => API.patch(`/services/${id}`, updatedServiceData);
//Session API
export const createSession = (updatedSessionData) => API.post("/sessions", updatedSessionData);
export const getUserSessions = (userId) => API.get(`/sessions/user/${userId}`, userId);
export const deleteSession = (id) => API.delete(`/sessions/${id}`);
export const updateSession = (id, updatedSessionData) => API.patch(`/sessions/${id}`, updatedSessionData);
//Archive API
export const createArchive = (updatedArchiveData) => API.post("/archives", updatedArchiveData);
export const getUserArchives = (userId) => API.get(`/archives/user/${userId}`, userId);
export const deleteArchive = (id) => API.delete(`/archives/${id}`);
export const updateArchive = (id, updatedArchiveData) => API.patch(`/archives/${id}`, updatedArchiveData);
export const fetchArchiveImages = (id) => API.get(`/archives/${id}/images`);


//Category API
export const createCategory = (categoryData) => API.post("/categories", categoryData);
export const getUserCategories = (userId) => API.get(`/categories/user/${userId}`, userId);
export const deleteCategory = (id) => API.delete(`/categories/${id}`);
export const updateCategory = (id, updatedCategoryData) => API.patch(`/categories/${id}`, updatedCategoryData);

//Campain API
export const createSMS = (updatedData) => API.post("/sms/createSMS", updatedData);
export const getSMS = (userId) => API.get(`/sms/getSMS/${userId}`, userId);
export const updateSMS = (id, updatedSMSData) => API.patch(`/sms/${id}`, updatedSMSData);
export const sendSMSMessage =  ({message, userId}) => { console.log({message, userId})
     return API.post("sms/sendSMS", {message, userId})}
     export const sendEmailsHandler =  ({userId, subject, text, senderEmail, senderPassword}) => { console.log({userId, subject, text, senderEmail, senderPassword})
     return API.post("/sendMail/emails", {userId, subject, text, senderEmail, senderPassword})}
     export const getClientsCSV = (userId) =>API.get(`/clients/export-csv/${userId}`, { responseType: "blob" });







