import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HeaderAdmin from '../components/HeaderAdmin';
import { useParams } from 'react-router-dom';
import { selectUser } from '../redux/features/authSlice';
import { toast } from 'react-toastify';
import { updateSMS } from '../redux/features/smsSlice';
import { useTranslation } from 'react-i18next';

const EditMessage = ({goToCampains}) => {
  const {id} = useParams();
    const [messageData, setMessageData] = useState(null);
  const messages = useSelector((state) => state.sms.sms);
  const { t } = useTranslation();

  useEffect(() => {
    if (messages && messages.length > 0) {
      const singleMessage = messages.find((message) => message._id === id);
      if (singleMessage) {
        setMessageData(singleMessage);
        setSMSMessage(singleMessage.message || ''); // Set initial message text if available
        setSelectedOption(singleMessage.selectedOption || ''); // Set initial kind if available
      }
    }
  }, [messages, id]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [SMSmessage, setSMSMessage] = useState('');

      const [selectedOption, setSelectedOption] = useState('');
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
      };
    const onInputChange = (e) => {
      const { value } = e.target;
      setSMSMessage(value);
  }
      const updateMessage = (e) => {
          e.preventDefault();
          const updatedSMSData = {message:SMSmessage, selectedOption: messageData.selectedOption}
          
          dispatch(updateSMS({ id, updatedSMSData, toast} ));
          setSMSMessage('');
          navigate('/admin');
        }
        
  return (
    <div className=" min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200 overflow-y-auto">
    {/* Header */}
    <HeaderAdmin />
    <div className="w-full md:p-8 p-4  fixed top-0 left-0 z-10">
        <h2 className="text-3xl font-bold text-white mb-6 mt-[80px]">{t('campaigns.editMessage')}</h2>
    <form onSubmit={updateMessage}>
        <textarea
        placeholder={t('campaigns.messagePlaceholder')}
          value={SMSmessage}
          onChange={onInputChange}
          className="p-2 w-full h-[150px] md:h-[50px] border border-gray-300 rounded-md resize-none"
        />
        {messageData?.kind=== 'auto' && 
       <div className="flex flex-col space-y-2">
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="0h"
           checked={selectedOption === '0h'}
           onChange={handleOptionChange}
           className="form-radio text-blue-500"
         />
         <span>{t('campaigns.birthdayMessage')}</span>
       </label>
 
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="24h"
           checked={selectedOption === '24h'}
           onChange={handleOptionChange}
           className="form-radio text-blue-500"
         />
         <span>{t('campaigns.24hEarlier')}</span>
       </label>
 
       <label className="flex items-center space-x-2">
         <input
           type="radio"
           value="48h"
           checked={selectedOption === '48h'}
           onChange={handleOptionChange}
           className="form-radio text-blue-500"
         />
         <span>{t('campaigns.48hEarlier')}</span>
       </label>
     </div> }
        <div className="flex justify-end space-x-3">
            <button
                type="button"
                onClick={goToCampains}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
                {t('common.cancel')}
            </button>
            <button
                type="submit"
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
            >
                {t('common.save')}
            </button>
        </div>
      </form></div></div>
  )
}

export default EditMessage