import React, {useState, useEffect} from 'react'
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import HeaderAdmin from '../components/HeaderAdmin';
import { updateWorker } from '../redux/features/workerSlice';
import ColorDropdown from '../components/ColorDropdown';
import { useTranslation } from 'react-i18next';

const initialState = {
 firstName:'',
 lastName:'',
 email:'',
 phone:'',
 color:''
}

const EditWorker = ({goToClients})=> {
    const [workerData, setWorkerData] = useState(initialState);
    const { t } = useTranslation();
    const error = useSelector((state) => state.worker.error);
    const workers = useSelector((state) => state.worker.workers);

    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
    
    useEffect(()=>{
        const singleWorker = workers.find((worker)=> worker._id === id);
        console.log("Found worker for editing:", singleWorker);
        if (singleWorker) {
            setWorkerData({...singleWorker});
        }
    },[id, workers]);

    const {firstName, lastName, email, phone, color} = workerData;

    useEffect(()=>{
        error && toast.error(error)
    },[error])
 
    const onInputChange = (e) =>{
        const {name, value} = e.target;
        setWorkerData({...workerData, [name]: value})
    };

    const takeColor = (color) => {
        setWorkerData(prevState => ({
          ...prevState,
          color: color
        }));
    };

    const handleSubmit = (e) =>{
        e.preventDefault();
        const updatedWorkerData = {...workerData, name:user?.result?.name}
        console.log("Submitting updated worker data:", updatedWorkerData);
        dispatch(updateWorker({id, updatedWorkerData, navigate, toast}))
            .then((result) => {
                console.log("Update result:", result);
                handleClear();
            })
            .catch((err) => {
                console.error("Update error:", err);
            });
    };
      
    const handleClear = () =>{
        setWorkerData({ firstName:'', lastName:'', email:'', phone:'', color:''})
    };
     
    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200 overflow-y-auto">
            {/* Header */}
            <HeaderAdmin />
            <div className="w-full md:p-8 p-4 fixed top-0 left-0 z-10">
                <h2 className="text-3xl font-bold text-white mb-6 mt-[80px]">{t('workers.editWorker')}</h2>
                <form onSubmit={handleSubmit} className="space-y-6 w-full">
                    <div className="flex flex-col w-full">
                        <div className='md:w-1/2 w-full '>
                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-100">{t('workers.firstName')}</label>
                            <input onChange={onInputChange} value={workerData.firstName || ''} id="firstName" name="firstName" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
                        </div>
                        <div className='md:w-1/2 w-full '>
                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-100">{t('workers.lastName')}</label>
                            <input onChange={onInputChange} value={workerData.lastName || ''} id="lastName" name="lastName" type="text" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
                        </div>
                        <div className='md:w-1/2 w-full '>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-100">{t('workers.email')}</label>
                            <input onChange={onInputChange} value={workerData.email || ''} id="email" name="email" type="email" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
                        </div>
                        <div className='md:w-1/2 w-full '>
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-100">{t('workers.phone')}</label>
                            <input onChange={onInputChange} value={workerData.phone || ''} id="phone" name="phone" type="tel" className="mt-1 w-full px-4 py-2 border border-gray-200 rounded-md shad3w-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
                        </div>
                        <div className='md:w-1/2 w-full '>
                            <label htmlFor="color" className="block text-sm font-medium text-gray-100">{t('workers.color')}</label>
                            <ColorDropdown forwardColor={takeColor} oldColor={color || 'darkgray'} />
                        </div>
                    </div>
                    <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
                        {t('workers.submit')}
                    </button>
                </form>
            </div>
        </div>
    )
}

export default EditWorker;