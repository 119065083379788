import React from 'react'
import {useSelector} from 'react-redux';
import DownloadCSV from '../../components/DownloadContacts';
import { selectUser } from '../../redux/features/authSlice';

const Csv = () => {
    const user = useSelector(selectUser);
    const loading = useSelector((state) => state.client.loading);
    const userId = user?.result._id;
    console.log(userId);
  return (
    <DownloadCSV userId={userId}/>
  )
}

export default Csv;