import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faMicrophone, faStop } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { createSession } from '../redux/features/sessionSlice';
import TimeInput from '../components/DatePicker';
import { createClient } from '../redux/features/clientSlice';
import { useTranslation } from 'react-i18next';

const initialState = {
  firstName:'',
  lastName:'',
  phone:'',
  email:'',
  date:'',
  time:'',
  price:'',
  workerFirstName:'',
  workerLastName:'',
  service:'',
  duration:'',
  liar:''
}

const AddSession = ({workers, services, categories, goToSessions})=> {
      const user = useSelector((state) => state.auth.user);
      const clients = useSelector((state) => state.client.clients);
      const [sessionData, setSessionData] = useState(initialState);
      const [countryCode, setCountryCode] = useState('');
      const [isOpenWorker, setIsOpenWorker] = useState(false);
      const [isOpenCategory, setIsOpenCategory] = useState(false);
      const [isOpenService, setIsOpenService] = useState(false);
      const [selectedWorker, setSelectedWorker] = useState(null); 
      const [selectedService, setSelectedService] = useState(null); 
      const [suggestedClients, setSuggestedClients] = useState([]);
      const [takeService, setTakeService] = useState([]);
      
      const dispatch = useDispatch();
      const navigate = useNavigate();
      const { t } = useTranslation();

      const searchClients = (searchTerm) => {
        if (!searchTerm || searchTerm.length < 2) {
          setSuggestedClients([]);
          return;
        }

        const matches = clients.filter((client) => {
          const searchLower = searchTerm.toLowerCase();
          return (
            client.firstName?.toLowerCase().includes(searchLower) ||
            client.lastName?.toLowerCase().includes(searchLower) ||
            client.phone?.includes(searchTerm) ||
            client.email?.toLowerCase().includes(searchLower)
          );
        });
        
        setSuggestedClients(matches);
      };

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSessionData({ ...sessionData, [name]: value });
        
        // Only search for clients when relevant fields are changed
        if (['phone', 'firstName', 'lastName', 'email'].includes(name)) {
          searchClients(value);
        }
      };

      const handlePhoneChange = (value) => {
        const formattedValue = `+${value}`;
        setSessionData({ ...sessionData, phone: formattedValue });
        searchClients(formattedValue);
      };

      const handleSelectClient = (client) => {
        setSessionData({
          ...sessionData,
          firstName: client.firstName,
          lastName: client.lastName,
          phone: client.phone,
          email: client.email,
          liar: client.liar
        });
        setSuggestedClients([]);
      };

      const toggleDropdownWorker = () => {
        setIsOpenWorker(!isOpenWorker);
      };

      const handleSelectWorker = (worker) => {
        setSelectedWorker(worker); 
        setSessionData((prevState) => ({
          ...prevState,
          workerFirstName: worker.firstName,
          workerLastName: worker.lastName,
        }));
        setIsOpenWorker(false); 
      };

      const toggleDropdownService = () => {
        setIsOpenCategory(!isOpenCategory);
        setIsOpenService(false);
      };

      const handleSelectCategory = (service) => {
        setSelectedService(service); 
        setSessionData((prevState) => ({
          ...prevState,
          service: service.service,
          price: service.price,
          duration: service.duration
        }));
        setIsOpenCategory(false); 
        setIsOpenService(false); 
      };

      const closeCategory = (cat) => {
        let match = services.filter(item => item.category === cat);
        setTakeService(match);
        setIsOpenCategory(false);
        setIsOpenService(true);
      };

      const getUserCountryCode = async () => {
        try {
          const response = await fetch('https://ipapi.co/json/');
          const data = await response.json();
          setCountryCode(data.country_code.toLowerCase()); 
        } catch (error) {
        }
      };

      useEffect(() => {
        getUserCountryCode();
      }, []);

      const handleTimeChange = (selectedTime) => {
        setSessionData((prevState) => ({
          ...prevState,
          time: selectedTime 
        }));
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Check required fields
        if (!sessionData.phone || !sessionData.date || !sessionData.time) {
          toast.error(t('sessions.errorMissingFields'));
          return;
        }
        
        try {
          const existingClient = clients.find(client => client.phone === sessionData.phone);
          
          if (existingClient && sessionData.phone) {
            toast.info(t('clients.alreadyExists'));
          }

          await dispatch(createSession({
            sessionData: { ...sessionData, userId: user?.result?._id },
            toast
          }));

          if (!existingClient && sessionData.phone) {
            await dispatch(createClient({
              updatedClientData: {
                firstName: sessionData.firstName,
                lastName: sessionData.lastName,
                phone: sessionData.phone,
                email: sessionData.email,
                userId: user?.result?._id
              }
            }));
            toast.success(t('clients.successCreate'));
          }

          handleClear();
          goToSessions();
        } catch (error) {
          console.error('Error:', error);
          toast.error(t('toast.error'));
        }
      };
   
      const handleClear = () => {
        setSessionData(initialState);
        setSelectedService(null);
        setSelectedWorker(null);
        setSuggestedClients([]);
      };

      const takeClient = (phone) =>{
        const takePerson = clients.find(client => client.phone === phone);

        if (takePerson) {
          // Ažurirajte stanja sa podacima klijenta
          setSessionData({
            ...sessionData,
            phone: takePerson.phone,
            firstName: takePerson.firstName,
            lastName: takePerson.lastName,
            email: takePerson.email,
            liar:takePerson.liar
          });
        }
      }

  return (
<div>
       <h2 className="text-2xl font-bold text-white mb-4">{t('sessions.addNewSession')}</h2>
        <form className="space-y-4 md:space-y-6 w-full" onSubmit={handleSubmit} noValidate>
            <div className="flex md:flex-row flex-col w-full md:gap-4 gap-2">
            <div className='md:w-1/3 w-full'>
            <label htmlFor="client" className="block text-xs font-medium text-gray-200 mb-1">{t('sessions.phone')}</label>
            <PhoneInput
                country={countryCode}
                enableSearch={true}
                value={sessionData.phone}
                onChange={handlePhoneChange}
                inputStyle={{
                    width: '100%',
                    padding: '6px 8px',
                    borderRadius: '7px',
                    border: '1px solid #ccc',
                    paddingLeft:'50px',
                    height:'32px',
                    marginTop: '0'
                }}
                required
            />
            </div>
            <div className='md:w-1/3 w-full'>
                <label htmlFor="firstName" className="block text-xs font-medium text-gray-100">{t('sessions.firstName')}</label>
                <div className="relative">
                    <input 
                        onChange={handleInputChange} 
                        value={sessionData.firstName}  
                        id="firstName" 
                        name="firstName" 
                        type="text" 
                        placeholder={t('sessions.firstNamePlaceholder')}
                        className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                    />
                </div>
            </div>
            <div className='md:w-1/3 w-full'>
                <label htmlFor="lastName" className="block text-xs font-medium text-gray-100">{t('sessions.lastName')}</label>
                <div className="relative">
                    <input 
                        onChange={handleInputChange} 
                        value={sessionData.lastName}  
                        id="lastName" 
                        name="lastName" 
                        type="text" 
                        placeholder={t('sessions.lastNamePlaceholder')}
                        className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                    />
                </div>
            </div>
            </div>
            {suggestedClients.length > 0 && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                    {suggestedClients.map((client) => (
                        <div
                            key={client._id}
                            onClick={() => handleSelectClient(client)}
                            className="cursor-pointer hover:bg-green-100 p-2 border-b border-gray-200 last:border-b-0"
                        >
                            <div className="font-medium">{client.firstName} {client.lastName}</div>
                            <div className="text-sm text-gray-600">{client.phone}</div>
                            <div className="text-sm text-gray-600">{client.email}</div>
                        </div>
                    ))}
                </div>
            )}
            {sessionData.liar === 'yes' && (
                <p className='text-red-700 font-medium'>{t('sessions.brokenPromise')}</p>
            )}
            <div className="flex md:flex-row flex-col w-full md:gap-4 gap-2">
                <div className='md:w-1/3 w-full'>
                    <label htmlFor="email" className="block text-xs font-medium text-gray-100">{t('sessions.email')}</label>
                    <div className="relative">
                        <input 
                            onChange={handleInputChange} 
                            value={sessionData.email}  
                            id="email" 
                            name="email" 
                            type="email" 
                            placeholder={t('sessions.emailPlaceholder')}
                            className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                        />
                    </div>
                </div>
                <div className='relative md:w-1/3 w-full'>
                    <label htmlFor="service" className="block text-xs font-medium text-gray-100">{t('sessions.service')}</label>
                    <div 
                        className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none bg-white text-black cursor-pointer"
                        onClick={toggleDropdownService}
                    >
                        {selectedService ? `${selectedService.service}` : t('sessions.selectTreatment')}
                    </div>
                    
                    {isOpenCategory && (
                        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                            {categories.length > 0 ? (
                                categories.map((category) => (
                                    <li 
                                        key={category._id} 
                                        className="flex items-center justify-between cursor-pointer hover:bg-green-100 p-2 border-b border-gray-200 last:border-b-0"
                                        onClick={() => closeCategory(category.category)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faFolder} 
                                            style={{ color: category.colorFinish, fontSize: '1.2rem' }} 
                                        />
                                        <span className="ml-2">{category.category}</span>
                                    </li>
                                ))
                            ) : (
                                <li className="p-2 text-gray-500">{t('sessions.noCategories')}</li>
                            )}
                        </ul>
                    )}
                    {isOpenService && (
                        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                            {takeService.length > 0 ? (
                                takeService.map((service) => (
                                    <li 
                                        key={service._id}
                                        className="flex items-center justify-between cursor-pointer hover:bg-green-100 p-2 border-b border-gray-200 last:border-b-0"
                                        onClick={() => handleSelectCategory(service)}
                                    >
                                        <FontAwesomeIcon 
                                            icon={faFile} 
                                            style={{ color: service.category.colorFinish, fontSize: '1.2rem' }} 
                                        />
                                        <span className="ml-2">{service.service}</span>
                                    </li>
                                ))
                            ) : (
                                <li className="p-2 text-gray-500">{t('sessions.noServices')}</li>
                            )}
                        </ul>
                    )}
                </div>
                <div className='md:w-1/3 w-full'>
                    <label htmlFor="price" className="block text-xs font-medium text-gray-100">{t('sessions.price')}</label>
                    <input 
                        onChange={handleInputChange} 
                        value={sessionData.price}  
                        id="price" 
                        name="price" 
                        type="number" 
                        placeholder={t('sessions.pricePlaceholder')}
                        className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                    />
                </div>
            </div>
            <div className="flex md:flex-row flex-col w-full md:gap-4 gap-2">
                <div className='md:w-1/3 w-full'>
                    <label htmlFor="duration" className="block text-xs font-medium text-gray-100">{t('sessions.duration')}</label>
                    <input 
                        onChange={handleInputChange} 
                        value={sessionData.duration}  
                        id="duration" 
                        name="duration" 
                        type="number" 
                        placeholder={t('sessions.durationPlaceholder')}
                        className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                    />
                </div>
                <div className='relative md:w-1/3 w-full'>
                    <label htmlFor="worker" className="block text-xs font-medium text-gray-100">{t('sessions.worker')}</label>
                    <div 
                        className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none bg-white text-black cursor-pointer"
                        onClick={toggleDropdownWorker}
                    >
                        {selectedWorker ? `${selectedWorker.firstName} ${selectedWorker.lastName}` : t('sessions.selectWorker')}
                    </div>
                    
                    {isOpenWorker && (
                        <ul className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
                            {workers.map((worker) => (
                                <li 
                                    key={worker._id}
                                    className="flex items-center justify-between cursor-pointer hover:bg-green-100 p-2 border-b border-gray-200 last:border-b-0"
                                    onClick={() => handleSelectWorker(worker)}
                                >
                                    <span>{worker.firstName} {worker.lastName}</span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className='md:w-1/3 w-full'>
                    <label htmlFor="date" className="block text-xs font-medium text-gray-100">{t('sessions.date')}</label>
                    <input 
                        onChange={handleInputChange} 
                        value={sessionData.date}  
                        id="date" 
                        name="date" 
                        type="date" 
                        required 
                        className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                    />
                </div>
            </div>
            <div className="flex md:flex-row flex-col w-full md:gap-4 gap-2">
                <div className='md:w-1/3 w-full'>
                    <label htmlFor="time" className="block text-xs font-medium text-gray-100">{t('sessions.time')}</label>
                    <TimeInput 
                        value={sessionData.time} 
                        onTimeChange={handleTimeChange}
                        className="mt-1 w-full px-2 py-1.5 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none"
                        required
                    />
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:justify-end md:space-x-4 space-y-2 md:space-y-0">
                <button
                    type="submit"
                    className="w-full md:w-auto px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                    {t('sessions.addSession')}
                </button>
            </div>
        </form>
      </div>
  )
}

export default AddSession;