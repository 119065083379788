import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyUser = async () => {
      const token = searchParams.get("token");
console.log(token);

      if (!token) {
        alert("Invalid verification link!");
        navigate("/login"); // Ako nema tokena, vrati na login
        return;
      }

      try {
        const response = await fetch(`http://localhost:5000/sendMail/verify-email?token=${token}`, {
            method: "GET",
          });
        

        const data = await response.json();

        if (response.ok) {
          localStorage.setItem("token", data.token); // Čuvamo novi token u localStorage
          navigate("/login"); // Preusmeravamo korisnika u Admin panel
        } else {
          alert(data.message || "Verification failed!");
          navigate("/register"); // Ako nije uspešno, šaljemo na login
        }
      } catch (error) {
        console.error("Error verifying email:", error);
        alert("An error occurred. Please try again.");
        navigate("/register");
      }
    };

    verifyUser();
  }, [navigate, searchParams]);

  return <div>Verifying your email...</div>;
};

export default VerifyEmail;
