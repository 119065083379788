import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector,useDispatch } from 'react-redux';
import { faPhone, faEnvelope, faTimes } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';
import { useTranslation } from 'react-i18next';

const ClientData = ({clientData, closePopup,  takeClientHistory}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const formattedDate = new Date(clientData.createdAt).toLocaleDateString('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
  const formattedBirth = new Date(clientData.birth).toLocaleDateString('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
  
  const clientHistory = () =>{
     closePopup();
     takeClientHistory(clientData.phone);
  } 
   return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div 
      className="bg-white rounded-sm shadow-lg p-6 w-11/12 md:w-1/3"
      onClick={(e) => e.stopPropagation()} // Sprečava zatvaranje kada se klikne unutar popupa
    >
      <h2 className="text-xl font-bold mb-4">{t('clients.allClientData')}</h2>
      <div className="min-w-full bg-transparent rounded-sm shadow-lg bg-gray-50">
      <ul className="border-b border-transparent last:border-none">
      <li className="px-2 py-1 text-black">{clientData.firstName} {clientData.lastName}</li>
      <hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{clientData.phone}</li>
      <a href={`tel:${clientData.phone}`} ><FontAwesomeIcon className='p-2' icon={faPhone} /></a></div><hr/>
      <div className='flex flex-row justify-between'>
      <li className="px-2 py-1 text-black">{clientData.email}</li>
      <a href={`mailto:${clientData.email}`} ><FontAwesomeIcon className='p-2' icon={faEnvelope} /></a></div><hr/>
      <li className="px-2 py-1 text-black">{clientData.address}</li><hr/>
      <li className="px-2 py-1 text-black">{formattedBirth}</li><hr/>
      <li className="px-2 py-1 text-black">{clientData.description}</li><hr/>
      <li className="px-2 py-1 text-black">{t('clients.liar')}: {clientData.liar}</li><hr/>
      <li className="px-2 py-1 text-black"><i>{t('clients.created')} <b className='text-sm'>{formattedDate}</b> by  <b className='text-sm'>{user?.result?.email}</b></i></li><hr/>
      </ul>
      </div>
      <button 
        onClick={clientHistory} 
        className="mt-4 px-4 py-2 justify-end w-full bg-green-900 text-white rounded-sm"
      >
        {t('clients.clientsCard')}
      </button>
      <div className='flex justify-end'>
      <button 
        onClick={closePopup} 
        className="mt-4 px-2 py-1 bg-black text-white rounded-sm"
      >
        <FontAwesomeIcon className='p-2' icon={faTimes} />
      </button>
      </div>
    </div>
  </div>
  )
}

export default ClientData