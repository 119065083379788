import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../redux/features/authSlice';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLanguageOpen, setIsLanguageOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setIsLanguageOpen(false);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const renderAuthButtons = () => {
        if (user) {
            return (
                <div className="flex items-center space-x-4">
                    <span className="text-white">{user.email}</span>
                    <button
                        onClick={handleLogout}
                        className="text-white hover:text-green-100 px-4 py-2 text-sm font-medium transition-colors duration-200"
                    >
                        {t('auth.logout')}
                    </button>
                </div>
            );
        }
        return (
            <div className="flex items-center space-x-4">
                <Link
                    to="/login"
                    className="text-white hover:text-green-100 px-3 py-2 text-sm font-medium transition-colors duration-200"
                >
                    {t('auth.login')}
                </Link>
                <Link
                    to="/register"
                    className="text-white hover:text-green-100 px-3 py-2 text-sm font-medium transition-colors duration-200"
                >
                    {t('auth.register')}
                </Link>
            </div>
        );
    };

    return (
        <header className="bg-gradient-to-r from-green-900 via-green-500 to-green-700">
            <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                    <div className="flex">
                        <div className="flex-shrink-0 flex items-center">
                            <Link to="/" className="bg-black py-1 px-2 text-xl font-bold text-white hover:text-green-100 transition-colors duration-200">
                                {t('common.appName')}
                            </Link>
                        </div>
                        <div className="hidden sm:ml-6 sm:flex sm:items-center sm:space-x-8">
                            <Link
                                to="/"
                                className="text-white hover:text-green-100 px-3 py-2 text-sm font-medium transition-colors duration-200"
                            >
                                {t('common.home')}
                            </Link>
                            {user && (
                                <>
                                    <Link
                                        to="/admin"
                                        className="text-white hover:text-green-100 px-3 py-2 text-sm font-medium transition-colors duration-200"
                                    >
                                        {t('common.dashboard')}
                                    </Link>
                                    <Link
                                        to="/packages"
                                        className="text-white hover:text-green-100 px-3 py-2 text-sm font-medium transition-colors duration-200"
                                    >
                                        {t('common.packages')}
                                    </Link>
                                    <Link
                                        to="/billing"
                                        className="text-white hover:text-green-100 px-3 py-2 text-sm font-medium transition-colors duration-200"
                                    >
                                        {t('common.billing')}
                                    </Link>
                                    <Link
                                        to="/profile"
                                        className="text-white hover:text-green-100 px-3 py-2 text-sm font-medium transition-colors duration-200"
                                    >
                                        {t('common.profile')}
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="hidden sm:ml-6 sm:flex sm:items-center">
                        <div className="flex items-center space-x-4">
                            <div className="relative">
                                <button
                                    onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                                    className="text-white hover:text-green-100 px-3 py-2 text-sm font-medium transition-colors duration-200"
                                >
                                    {t('common.languages.title')}
                                    <svg
                                        className={`w-4 h-4 ml-1 transition-transform duration-200 ${isLanguageOpen ? 'transform rotate-180' : ''}`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>
                                {isLanguageOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-black rounded-md shadow-lg py-1 z-50">
                                        <button
                                            onClick={() => changeLanguage('en')}
                                            className={`block w-full text-left px-4 py-2 text-sm ${i18n.language === 'en' ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-800 hover:text-white'}`}
                                        >
                                            {t('common.languages.english')}
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('rs')}
                                            className={`block w-full text-left px-4 py-2 text-sm ${i18n.language === 'rs' ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-800 hover:text-white'}`}
                                        >
                                            {t('common.languages.serbian')}
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('fr')}
                                            className={`block w-full text-left px-4 py-2 text-sm ${i18n.language === 'fr' ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-800 hover:text-white'}`}
                                        >
                                            {t('common.languages.french')}
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('it')}
                                            className={`block w-full text-left px-4 py-2 text-sm ${i18n.language === 'it' ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-800 hover:text-white'}`}
                                        >
                                            {t('common.languages.italian')}
                                        </button>
                                        <button
                                            onClick={() => changeLanguage('es')}
                                            className={`block w-full text-left px-4 py-2 text-sm ${i18n.language === 'es' ? 'bg-gray-800 text-white' : 'text-gray-300 hover:bg-gray-800 hover:text-white'}`}
                                        >
                                            {t('common.languages.spanish')}
                                        </button>
                                    </div>
                                )}
                            </div>
                            {renderAuthButtons()}
                        </div>
                    </div>
                    <div className="-mr-2 flex items-center sm:hidden">
                        <button
                            onClick={toggleMenu}
                            className="inline-flex items-center justify-center p-2 rounded-md text-white hover:text-green-100 hover:bg-green-700 focus:outline-none"
                        >
                            <span className="sr-only">{t('common.menu')}</span>
                            {isMenuOpen ? (
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </nav>

            {/* Mobile menu */}
            {isMenuOpen && (
                <div className="sm:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <Link
                            to="/"
                            className="text-white hover:text-green-100 block px-3 py-2 rounded-md text-base font-medium"
                            onClick={() => setIsMenuOpen(false)}
                        >
                            {t('common.home')}
                        </Link>
                        {user && (
                            <>
                                <Link
                                    to="/admin"
                                    className="text-white hover:text-green-100 block px-3 py-2 rounded-md text-base font-medium"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {t('common.dashboard')}
                                </Link>
                                <Link
                                    to="/packages"
                                    className="text-white hover:text-green-100 block px-3 py-2 rounded-md text-base font-medium"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {t('common.packages')}
                                </Link>
                                <Link
                                    to="/billing"
                                    className="text-white hover:text-green-100 block px-3 py-2 rounded-md text-base font-medium"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {t('common.billing')}
                                </Link>
                                <Link
                                    to="/profile"
                                    className="text-white hover:text-green-100 block px-3 py-2 rounded-md text-base font-medium"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {t('common.profile')}
                                </Link>
                            </>
                        )}
                        {!user ? (
                            <>
                                <Link
                                    to="/login"
                                    className="text-white hover:text-green-100 block px-3 py-2 rounded-md text-base font-medium"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {t('auth.login')}
                                </Link>
                                <Link
                                    to="/register"
                                    className="text-white hover:text-green-100 block px-3 py-2 rounded-md text-base font-medium"
                                    onClick={() => setIsMenuOpen(false)}
                                >
                                    {t('auth.register')}
                                </Link>
                            </>
                        ) : (
                            <button
                                onClick={() => {
                                    handleLogout();
                                    setIsMenuOpen(false);
                                }}
                                className="text-white hover:text-green-100 block w-full text-left px-3 py-2 rounded-md text-base font-medium"
                            >
                                {t('auth.logout')}
                            </button>
                        )}
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;