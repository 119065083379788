import React, { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const NotificationContext = createContext();

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider = ({ children }) => {
  const { t } = useTranslation();

  const getTranslatedMessage = (key) => {
    const messageMap = {
      // Worker messages
      'workerAdded': 'toast.workerAdded',
      'workerDeleted': 'toast.workerDeleted',
      'workerUpdated': 'toast.workerUpdated',
      
      // Service messages
      'serviceAdded': 'toast.serviceAdded',
      'serviceDeleted': 'toast.serviceDeleted',
      'serviceUpdated': 'toast.serviceUpdated',
      
      // Session messages
      'sessionAdded': 'toast.sessionAdded',
      'sessionUpdated': 'toast.sessionUpdated',
      
      // Client messages
      'clientAdded': 'toast.clientAdded',
      'clientDeleted': 'toast.clientDeleted',
      'clientUpdated': 'toast.clientUpdated',
      
      // SMS messages
      'messageCreated': 'toast.messageCreated',
      'messageUpdated': 'toast.messageUpdated',
      'smsSent': 'toast.smsSent',
      
      // Archive messages
      'archiveAdded': 'toast.archiveAdded',
      'archiveDeleted': 'toast.archiveDeleted'
    };

    return t(messageMap[key] || key);
  };

  const showSuccess = (message) => {
    toast.success(getTranslatedMessage(message));
  };

  const showError = (message) => {
    toast.error(getTranslatedMessage(message));
  };

  const showWarning = (message) => {
    toast.warning(getTranslatedMessage(message));
  };

  const showInfo = (message) => {
    toast.info(getTranslatedMessage(message));
  };

  const value = {
    showSuccess,
    showError,
    showWarning,
    showInfo
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
}; 