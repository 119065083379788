import React, { useState } from 'react'
import HeaderAdmin from '../components/HeaderAdmin'
import { editProfile, selectUser } from '../redux/features/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../context/LanguageContext';

const EditProfile = () => {
    const user = useSelector(selectUser);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentLanguage } = useLanguage();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        city: user?.result?.city || "",
        address: user?.result?.address || "",
        apppassword: "",
    });

    const onInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        
        try {
            const updatedFormData = { ...formData, id: user.result._id };
            await dispatch(editProfile({ formData: updatedFormData, toast })).unwrap();
            toast.success(t('auth.profileUpdateSuccess'));
            navigate('/admin');
        } catch (error) {
            console.error('Profile update error:', error);
            toast.error(t('auth.profileUpdateError'));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200 overflow-y-auto">
            <HeaderAdmin />
            <div className="w-full md:p-8 p-4 fixed top-0 left-0 z-10">
                <h2 className="text-3xl font-bold text-white mb-6 mt-[80px]">{t('auth.editProfile')}</h2>
                <form onSubmit={handleSubmit} className="space-y-6 w-full" noValidate>
                    <div className="flex flex-col">
                        {/* Read-only fields */}
                        <div className='md:w-1/2 w-full mb-4'>
                            <label className="block text-xs font-medium text-gray-100">{t('auth.company')}</label>
                            <input 
                                value={user?.result?.company || ""} 
                                disabled
                                className="font-bold w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs bg-gray-100" 
                            />
                        </div>
                        <div className='md:w-1/2 w-full mb-4'>
                            <label className="block text-xs font-medium text-gray-100">{t('auth.person')}</label>
                            <input 
                                value={user?.result?.person || ""} 
                                disabled
                                className="font-bold w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs bg-gray-100" 
                            />
                        </div>
                        <div className='md:w-1/2 w-full mb-4'>
                            <label className="block text-xs font-medium text-gray-100">{t('auth.phone')}</label>
                            <input 
                                value={user?.result?.phone || ""} 
                                disabled
                                className="font-bold w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs bg-gray-100" 
                            />
                        </div>
                        <div className='md:w-1/2 w-full mb-4'>
                            <label className="block text-xs font-medium text-gray-100">{t('auth.email')}</label>
                            <input 
                                value={user?.result?.email || ""} 
                                disabled
                                className="font-bold w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs bg-gray-100" 
                            />
                        </div>

                        {/* Editable fields */}
                        <div className='md:w-1/2 w-full'>
                            <label htmlFor="city" className="block text-xs font-medium text-gray-100">{t('auth.city')}</label>
                            <input 
                                value={formData.city} 
                                onChange={onInputChange}
                                id="city" 
                                name="city" 
                                type="text" 
                                placeholder={t('auth.city')}
                                className="w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                            />
                        </div>
                        <div className='md:w-1/2 w-full'>
                            <label htmlFor="address" className="block text-xs font-medium text-gray-100">{t('auth.address')}</label>
                            <input 
                                value={formData.address} 
                                onChange={onInputChange}
                                id="address" 
                                name="address" 
                                type="text" 
                                placeholder={t('auth.address')}
                                className="w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                            />
                        </div>
                        <div className='md:w-1/2 w-full'>
                            <label htmlFor="apppassword" className="block text-xs font-medium text-gray-100">{t('auth.appPassword')}</label>
                            <input 
                                value={formData.apppassword} 
                                onChange={onInputChange}
                                placeholder={t('auth.appPasswordPlaceholder')} 
                                id="apppassword" 
                                name="apppassword" 
                                type="password" 
                                className="w-full px-4 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" 
                            />
                        </div>
                        <button 
                            type="submit" 
                            disabled={isLoading}
                            className={`bg-black text-white w-full mt-2 p-2 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                            {isLoading ? t('common.loading') : t('common.save')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default EditProfile