import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TimeInput = React.memo(({ onTimeChange, oldTime }) => {
  // Postavite default vrednost vremena (ako oldTime nije prisutan, koristi se 00:00)
  const defaultTime = oldTime ? new Date(`1970-01-01T${oldTime}:00`) : new Date('1970-01-01T00:00:00');

  // Inicijalizujte stanje sa defaultTime
  const [selectedTime, setSelectedTime] = useState(defaultTime);

  // Koristite useEffect da biste ažurirali selectedTime kada oldTime postane dostupno
  useEffect(() => {
    if (oldTime) {
      const updatedTime = new Date(`1970-01-01T${oldTime}:00`);
      setSelectedTime(updatedTime);
    }
  }, [oldTime]);

  const handleChange = (time) => {
    setSelectedTime(time);

    const hours = time.getHours().toString().padStart(2, '0'); // Dodaj nulu ako je jednocifren broj
    const minutes = time.getMinutes().toString().padStart(2, '0');

    // Kreiraj promenljivu u formatu "HH:mm"
    const formattedTime = `${hours}:${minutes}`;

    // Prosledi parent komponenti
    onTimeChange(formattedTime);
  };

  return (
    <DatePicker
      selected={selectedTime}
      onChange={handleChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={60}
      timeCaption="Time"
      dateFormat="HH:mm"
      className=" px-1 py-2 border border-gray-200 text-center h-9 w-1/2 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
    />
  );
});

export default TimeInput;
