import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faPhone } from '@fortawesome/free-solid-svg-icons';
import ClientsHistoryData from './ClientsHistoryData';
import { useTranslation } from 'react-i18next';

const ClientsHistory = ({selectedPhone, archives}) => {
  const [showClientHistory, setShowClientHistory] = useState(false);
  const [clientId, setClientData] = useState();
  const selectedClient = archives.filter(item=>item.phone === selectedPhone);
  const { firstName, lastName, phone } = selectedClient[0] || {};
  const { t } = useTranslation();
  
  const showClientData = (client)=>{
    setShowClientHistory(!showClientHistory);
    setClientData(client)
  }

  const formatDate = (date) => {
    const d = new Date(date);
    const month = d.toLocaleString('sr-RS', { month: 'long' });
    const year = d.getFullYear();
    return `${month} ${year}`;
  };

  const totalPrice = selectedClient.reduce((sum, item) => sum + parseFloat(item.price || 0), 0).toFixed(2);
  
  return (
    <div className="text-white">
      <h2 className="text-xl font-bold mb-1">{t('clientHistory.title')}</h2>
      <div className="flex flex-row justify-between">
        <p className="text-sm font-bold"> <FontAwesomeIcon icon={faUser}/> {firstName} {lastName} </p>
        <p className="text-sm font-bold" >{phone}</p>
      </div>
      <hr/>
       
      {!showClientHistory && <ul className='mt-3'>
        {selectedClient
          .slice() 
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map(item =>
            <div className="flex flex-col" key={item._id}>
              <li className="flex flex-row justify-between border-b-white text-xs my-2 font-bold">
                {formatDate(item.date)} {item.service}
                <FontAwesomeIcon icon={faBars} onClick={()=>showClientData(item._id)} />
              </li>
              <hr/>
            </div>
          )}
        <p>{t('clientHistory.total')}: {totalPrice}</p>
      </ul>}
      {showClientHistory && <ClientsHistoryData clientData={clientId} archives={archives} closeClientData={()=>setShowClientHistory(false)} />}
    </div>
  )
}

export default ClientsHistory
