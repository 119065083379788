import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api.js';
import i18next from 'i18next';

export const createSession = createAsyncThunk(
    "session/createSession",
    async ({sessionData, toast}, {dispatch}) => {
        try {
            const response = await api.createSession(sessionData);
            toast.success(i18next.t('toast.sessionAdded'));
            await dispatch(getUserSessions({ userId: sessionData.userId }));
            return response.data;
        } catch (error) {
            toast.error(i18next.t('toast.error'));
            throw error;
        }
    }
);

export const getUserSessions = createAsyncThunk(
    "session/getUserSessions",
    async ({userId}, {rejectWithValue}) => {
        try {
            const response = await api.getUserSessions(userId);
            if (!response.data) {
                return rejectWithValue({ message: 'No sessions found' });
            }
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: 'Error fetching sessions' });
        }
    }
);

export const deleteSession = createAsyncThunk(
    "session/deleteSession",
    async ({id, toast}, {dispatch}) => {
        try {
            const response = await api.deleteSession(id);
            toast.success(i18next.t('toast.sessionDeleted'));
            return { id };
        } catch (error) {
            toast.error(i18next.t('toast.error'));
            throw error;
        }
    }
);

export const updateSession = createAsyncThunk(
    "session/updateSession",
    async ({id, updatedSessionData, navigate, toast, message}, {rejectWithValue}) => {
        try {
            const response = await api.updateSession(id, updatedSessionData);
            toast.success(i18next.t('toast.sessionUpdated'));
            navigate('/admin');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || { message: 'An error occurred' });
        }
    }
);

{/*
export const getClients = createAsyncThunk(
    "clsessionetClient",
    async ({userId},{rejectWithValue})=>{
        try{
            
            const response = await api.getClients(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

*/}
const sessionSlice = createSlice({
    name: 'session',
    initialState: {
        session: {},
        sessions: [],
        userSession: [],
        error: "",
        loading: false
    },
    reducers: {
        resetError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createSession.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createSession.fulfilled, (state, action) => {
                state.loading = false;
                state.sessions = [...state.sessions, action.payload];
                state.error = null;
            })
            .addCase(createSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || 'An error occurred';
            })
            .addCase(getUserSessions.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getUserSessions.fulfilled, (state, action) => {
                state.loading = false;
                state.sessions = action.payload;
                state.error = null;
            })
            .addCase(getUserSessions.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || 'An error occurred';
            })
            .addCase(deleteSession.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteSession.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.id) {
                    state.sessions = state.sessions.filter((item) => item._id !== action.payload.id);
                }
                state.error = null;
            })
            .addCase(deleteSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || 'An error occurred';
            })
            .addCase(updateSession.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateSession.fulfilled, (state, action) => {
                state.loading = false;
                const {arg: {id}} = action.meta;
                if (id) {
                    state.sessions = state.sessions.map((item) => item._id === id ? action.payload : item);
                }
                state.error = null;
            })
            .addCase(updateSession.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || 'An error occurred';
            })
    }
});

export default sessionSlice.reducer;