import { useEffect } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Home from './pages/Home.js';
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import Admin from './pages/Admin.js';
import Packages from './pages/Packages.js';
import "react-toastify/dist/ReactToastify.css";
import { setUser, logout, checkTokenValidity } from './redux/features/authSlice.js';
import './App.css';
import { Suspense } from 'react';
import PrivateRoute from './components/PrivateRoute.js';
import EditClient from './edits/EditClient.js';
import EditSession from './edits/EditSession.js';
import EditArchive from './edits/EditArchive.js';
import PageNotFound from './pages/PageNotFound.js';
import EditCategory from './edits/EditCategory.js';
import EditService from './edits/EditService.js';
import EditMessage from './campains/EditMessage.js';
import EditWorker from './edits/EditWorker.js';
import VerifyEmail from './mail/VerifyEmail.js';
import ForgotPassword from './mail/ForgotPassword.js';
import ResetPassword from './mail/ResetPassword.js';
import Profile from './pages/Profile.js';
import EditProfile from './edits/EditProfile.js';
import { verifyToken } from './redux/api.js';
import { LanguageProvider } from './context/LanguageContext.js';
import Billing from './pages/Billing';
import { NotificationProvider } from './components/NotificationProvider';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const storedProfile = localStorage.getItem("Profile");
    if (storedProfile) {
      const { token } = JSON.parse(storedProfile);
      if (!checkTokenValidity(token)) {
        dispatch(logout());
      }
    }
  }, [dispatch]);

  return (
    <BrowserRouter>
      <NotificationProvider>
        <ToastContainer autoClose={1000} />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/packages' element={<Packages />} />
          <Route path='/admin' element={<PrivateRoute><Admin /></PrivateRoute> } />
          <Route path='/profile' element={<PrivateRoute><Profile /></PrivateRoute> } />
          <Route path="/editProfile" element={<PrivateRoute><EditProfile /></PrivateRoute> } />
          <Route path="/editClient/:id" element={<PrivateRoute><EditClient /></PrivateRoute> } />
          <Route path="/editSession/:id" element={<PrivateRoute><EditSession /></PrivateRoute> } />
          <Route path="/editArchived/:id" element={<PrivateRoute><EditArchive /></PrivateRoute> } />
          <Route path="/editCategory/:id" element={<PrivateRoute><EditCategory /></PrivateRoute> } />
          <Route path="/editService/:id" element={<PrivateRoute><EditService /></PrivateRoute> } />
          <Route path="/editMessage/:id" element={<PrivateRoute><EditMessage /></PrivateRoute> } />
          <Route path="/editWorkers/:id" element={<PrivateRoute><EditWorker /></PrivateRoute> } />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/billing" element={<Billing />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </NotificationProvider>
    </BrowserRouter>
  );
}

export default function WrappedApp(){
  return(
    <Suspense fallback="...loading">
      <LanguageProvider>
        <App/>
      </LanguageProvider>
    </Suspense>
  )
}
