import React, { useState } from "react";
import Header from "../components/Header";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("http://localhost:5000/sendMail/forgot-password", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            alert(data.message);
        } catch (error) {
            console.error("Error:", error);
            alert("Something went wrong.");
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
        {/* Header */}
      <Header />
        <div className="flex-grow pt-20 flex items-center justify-center bg-gradient-to-br from-green-900 via-green-500 to-green-200">
    <div className="bg-white p-8 shadow-lg w-full max-w-md">
      <h2 className="text-2xl font-bold text-center text-gray-800">Forgot Password</h2>
            <form onSubmit={handleSubmit}>
         <div className="flex flex-col">  
                <input
                    type="email"
                    placeholder={t('auth.email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm"
                />
                 <button
            type="submit"
            className="w-full mt-2 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
          >
            Reset Password
          </button>
          </div>
            </form>
        </div> 
        </div></div>
    );
};

export default ForgotPassword;
