import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import HeaderAdmin from '../components/HeaderAdmin';

const Billing = () => {
    const { t } = useTranslation();
    const [showPaymentSlip, setShowPaymentSlip] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

    const handlePaymentSlip = () => {
        setShowPaymentSlip(true);
    };

    const handlePayPalSuccess = (data) => {
        console.log('PayPal payment successful:', data);
        // Handle successful payment
    };

    const handlePayPalError = (error) => {
        console.error('PayPal payment error:', error);
        // Handle payment error
    };

    const handleCardPayment = (e) => {
        e.preventDefault();
        // Handle card payment
    };

    return (
        <div className="min-h-screen bg-gradient-to-br from-green-900 via-green-500 to-green-200">
            <HeaderAdmin />
            <div className="container mx-auto px-4 py-8 pt-20">
                <h1 className="text-3xl font-bold mb-8 text-white">{t('billing.title')}</h1>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                    {/* PayPal Payment */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <div className="flex items-center justify-center mb-6">
                            <img 
                                src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/checkout-logo-large.png" 
                                alt="PayPal" 
                                className="h-12"
                            />
                        </div>
                        <div className="text-center mb-4">
                            <p className="text-gray-600 mb-2">PayPal - Sigurno i brzo plaćanje</p>
                            <p className="text-sm text-gray-500">Platite kroz PayPal nalog ili karticom</p>
                        </div>
                        <PayPalScriptProvider options={{ 
                            "client-id": "YOUR_PAYPAL_CLIENT_ID",
                            currency: "USD"
                        }}>
                            <PayPalButtons
                                createOrder={(data, actions) => {
                                    return actions.order.create({
                                        purchase_units: [
                                            {
                                                amount: {
                                                    value: "10.00"
                                                }
                                            }
                                        ]
                                    });
                                }}
                                onApprove={handlePayPalSuccess}
                                onError={handlePayPalError}
                                style={{
                                    layout: "vertical",
                                    color: "blue",
                                    shape: "rect",
                                    label: "pay"
                                }}
                            />
                        </PayPalScriptProvider>
                    </div>

                    {/* Card Payment */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold mb-4">{t('billing.cardPayment')}</h2>
                        <form onSubmit={handleCardPayment}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('billing.cardPaymentDetails.cardNumber')}
                                </label>
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 border rounded-md"
                                    placeholder="1234 5678 9012 3456"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('billing.cardPaymentDetails.expiryDate')}
                                </label>
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 border rounded-md"
                                    placeholder="MM/YY"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('billing.cardPaymentDetails.cvv')}
                                </label>
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 border rounded-md"
                                    placeholder="123"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700 mb-1">
                                    {t('billing.cardPaymentDetails.cardHolder')}
                                </label>
                                <input
                                    type="text"
                                    className="w-full px-3 py-2 border rounded-md"
                                    placeholder="John Doe"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
                            >
                                {t('billing.cardPaymentDetails.pay')}
                            </button>
                        </form>
                    </div>

                    {/* Bank Transfer */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-xl font-semibold mb-4">{t('billing.bankTransfer')}</h2>
                        <button
                            onClick={handlePaymentSlip}
                            className="w-full bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700"
                        >
                            {t('billing.paymentSlipDownload')}
                        </button>
                    </div>
                </div>

                {/* Payment Slip Modal */}
                {showPaymentSlip && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-6 rounded-lg max-w-md w-full">
                            <h2 className="text-xl font-semibold mb-4">{t('billing.paymentSlipTitle')}</h2>
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t('billing.paymentDetails.accountNumber')}
                                    </label>
                                    <p className="mt-1">840-123456-78</p>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t('billing.paymentDetails.recipient')}
                                    </label>
                                    <p className="mt-1">PlannerData d.o.o.</p>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t('billing.paymentDetails.purpose')}
                                    </label>
                                    <p className="mt-1">Plata usluge</p>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t('billing.paymentDetails.amount')}
                                    </label>
                                    <p className="mt-1">10.000,00 {t('billing.paymentDetails.currency')}</p>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t('billing.paymentDetails.referenceNumber')}
                                    </label>
                                    <p className="mt-1">123-456-789</p>
                                </div>
                            </div>
                            <div className="mt-6 flex justify-end space-x-4">
                                <button
                                    onClick={() => setShowPaymentSlip(false)}
                                    className="px-4 py-2 border rounded-md hover:bg-gray-100"
                                >
                                    {t('common.cancel')}
                                </button>
                                <button
                                    onClick={() => {
                                        // Handle payment slip download
                                        setShowPaymentSlip(false);
                                    }}
                                    className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                >
                                    {t('billing.paymentSlipDownload')}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Billing; 