import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import HeaderAdmin from '../components/HeaderAdmin';

const Packages = () => {
  const { t } = useTranslation();

  const features = {
    free: [
      { included: true, text: t('packages.features.basicScheduling') },
      { included: true, text: t('packages.features.clientManagement') },
      { included: true, text: t('packages.features.basicReports') },
      { included: false, text: t('packages.features.imageUpload') },
      { included: false, text: t('packages.features.financeOverview') },
      { included: false, text: t('packages.features.advancedAnalytics') },
      { included: false, text: t('packages.features.prioritySupport') },
    ],
    basic: [
      { included: true, text: t('packages.features.basicScheduling') },
      { included: true, text: t('packages.features.clientManagement') },
      { included: true, text: t('packages.features.basicReports') },
      { included: true, text: t('packages.features.imageUpload') },
      { included: true, text: t('packages.features.financeOverview') },
      { included: false, text: t('packages.features.advancedAnalytics') },
      { included: false, text: t('packages.features.prioritySupport') },
    ],
    pro: [
      { included: true, text: t('packages.features.basicScheduling') },
      { included: true, text: t('packages.features.clientManagement') },
      { included: true, text: t('packages.features.basicReports') },
      { included: true, text: t('packages.features.imageUpload') },
      { included: true, text: t('packages.features.financeOverview') },
      { included: true, text: t('packages.features.advancedAnalytics') },
      { included: true, text: t('packages.features.prioritySupport') },
    ]
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-900 via-green-500 to-green-200">
      <HeaderAdmin />
      <div className="py-12 px-4 sm:px-6 lg:px-8 pt-20">
        <div className="max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              {t('packages.title')}
            </h2>
            <p className="mt-4 text-xl text-white">
              {t('packages.subtitle')}
            </p>
          </div>

          <div className="mt-12 grid gap-8 lg:grid-cols-3">
            {/* Free Trial Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="px-6 py-8">
                <h3 className="text-2xl font-bold text-gray-900 text-center">
                  {t('packages.freeTrial.title')}
                </h3>
                <p className="mt-4 text-gray-500 text-center">
                  {t('packages.freeTrial.description')}
                </p>
              </div>
              <div className="px-6 pt-6 pb-8">
                <ul className="space-y-4">
                  {features.free.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <FontAwesomeIcon
                        icon={feature.included ? faCheck : faTimes}
                        className={`h-5 w-5 ${
                          feature.included ? 'text-green-500' : 'text-red-500'
                        }`}
                      />
                      <span className="ml-3 text-gray-700">{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="px-6 py-8 bg-gray-50">
                <button className="w-full bg-green-600 text-white rounded-md py-2 px-4 hover:bg-green-700 transition-colors">
                  {t('packages.freeTrial.startButton')}
                </button>
              </div>
            </div>

            {/* Basic Package Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden transform scale-105">
              <div className="px-6 py-8 bg-green-600">
                <h3 className="text-2xl font-bold text-white text-center">
                  {t('packages.basic.title')}
                </h3>
                <p className="mt-4 text-white text-center">
                  <span className="text-4xl font-bold">$19</span>
                  <span className="text-white">/month</span>
                </p>
              </div>
              <div className="px-6 pt-6 pb-8">
                <ul className="space-y-4">
                  {features.basic.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <FontAwesomeIcon
                        icon={feature.included ? faCheck : faTimes}
                        className={`h-5 w-5 ${
                          feature.included ? 'text-green-500' : 'text-red-500'
                        }`}
                      />
                      <span className="ml-3 text-gray-700">{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="px-6 py-8 bg-gray-50">
                <button className="w-full bg-green-600 text-white rounded-md py-2 px-4 hover:bg-green-700 transition-colors">
                  {t('packages.basic.selectButton')}
                </button>
              </div>
            </div>

            {/* Pro Package Card */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden">
              <div className="px-6 py-8">
                <h3 className="text-2xl font-bold text-gray-900 text-center">
                  {t('packages.pro.title')}
                </h3>
                <p className="mt-4 text-gray-500 text-center">
                  <span className="text-4xl font-bold">$49</span>
                  <span className="text-gray-500">/month</span>
                </p>
              </div>
              <div className="px-6 pt-6 pb-8">
                <ul className="space-y-4">
                  {features.pro.map((feature, index) => (
                    <li key={index} className="flex items-center">
                      <FontAwesomeIcon
                        icon={feature.included ? faCheck : faTimes}
                        className={`h-5 w-5 ${
                          feature.included ? 'text-green-500' : 'text-red-500'
                        }`}
                      />
                      <span className="ml-3 text-gray-700">{feature.text}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="px-6 py-8 bg-gray-50">
                <button className="w-full bg-green-600 text-white rounded-md py-2 px-4 hover:bg-green-700 transition-colors">
                  {t('packages.pro.selectButton')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Packages; 