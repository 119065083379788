import { useState } from 'react';
import { selectUser } from '../../redux/features/authSlice';
import {useSelector} from 'react-redux';
import { sendEmailsHandler } from '../../redux/api';
import {toast} from 'react-toastify';
import { useTranslation } from 'react-i18next';

function SendMail() {
    const user = useSelector(selectUser);
    const userId = user?.result._id;
    const senderEmail = user?.result.email;
    const { t } = useTranslation();
    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        let senderPassword = password.replace(/\s+/g, '');
        e.preventDefault();
        try {
            await sendEmailsHandler({ userId, subject, text, senderEmail, senderPassword });
            toast.success(t('mail.bulkEmailsSent'));
        } catch (error) {
            console.error('Error sending bulk emails:', error);
            toast.error(t('mail.bulkEmailsFailed'));
        }
        setSubject('');
        setText('');
        setPassword('');
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='flex flex-col'>
                <label className='p-1'>{t('mail.subject')}:</label>
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                />
            </div>
            <div className='flex flex-col'>
                <label className='p-1'>{t('mail.text')}:</label>
                <textarea
                    rows={10}
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required
                />
            </div>
            <div className='flex flex-col'>
                <label className='p-1'>{t('mail.appPassword')}:</label>
                <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <p className='italic underline mt-1'>
                    <a target='blank' href="https://www.youtube.com/watch?v=CqWFcUweiW8">{t('mail.tutorialLink')}</a>
                </p>
            </div>
            <button type="submit" className='w-full bg-black text-white mt-3 rounded-xs p-1'>{t('mail.sendBulkMails')}</button>
        </form>
    );
}

export default SendMail;
