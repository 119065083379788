import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api.js';
import i18next from 'i18next';

export const createCategory = createAsyncThunk(
    "category/createCategory",
    async ({categoryData, navigate, toast}, {rejectWithValue}) =>{
        try {
            const response = await api.createCategory(categoryData);
            toast.success(i18next.t('toast.categoryAdded'));
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)
export const getUserCategories = createAsyncThunk(
    "category/getUserCategories",
    async ({userId},{rejectWithValue})=>{
        
        try{
            const response = await api.getUserCategories(userId);
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

export const deleteCategory = createAsyncThunk(
    "category/deleteCategory",
    async ({id, toast},{rejectWithValue})=>{
        try{
            const response = await api.deleteCategory(id);
            toast.success(i18next.t('toast.categoryDeleted'))
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
export const updateCategory = createAsyncThunk(
    "category/updateCategory",
    async ({id, updatedCategoryData, navigate, toast},{rejectWithValue})=>{
        try{
            const response = await api.updateCategory(id, updatedCategoryData);            
            toast.success(i18next.t('toast.categoryUpdated'));
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
const categorySlice = createSlice({
    name:'category',
    initialState:{
        category:{},
        categories:[],
        userCategories:[],
        error:"",
        loading:false
    },
   
    extraReducers: (builder) => {
        builder
        .addCase(createCategory.pending, (state) => {
            state.loading = true;
        })
        .addCase(createCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.categories = [...state.categories, action.payload];
            
        })
        .addCase(createCategory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message || "Failed to create category" 
        })
        .addCase(getUserCategories.pending, (state) => {
            state.loading = true;
        })
        .addCase(getUserCategories.fulfilled, (state, action) => {
            state.loading = false;
            state.categories =  action.payload;
            
        })
        .addCase(getUserCategories.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message || "Failed to get categories"
        })
        .addCase(deleteCategory.pending, (state) => {
            state.loading = true;
        })
        .addCase(deleteCategory.fulfilled, (state, action) => {
            state.loading = false;
            const {arg : {id},} = action.meta;
            if(id){
              state.categories = state.categories.filter((item)=> item._id !== id)
            }
        })
        .addCase(deleteCategory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message || "Failed to delete category"
        })
        .addCase(updateCategory.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateCategory.fulfilled, (state, action) => {
            state.loading = false;
            const {arg : {id},} = action.meta;
            if(id){
              state.categories = state.categories.map((item)=> item._id === id ? action.payload : item)
            }
        })
        .addCase(updateCategory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.message || "Failed to update category"
        })
    }
});
export default categorySlice.reducer;