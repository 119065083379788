import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import * as api from '../api.js'
import { signin, signup, verifyToken } from "../api";

const selectAuthState = (state) => state.auth;

export const selectLoading = createSelector(
    [selectAuthState],
    (auth) => auth.loading,    
  );
  
  // Memoizovani selektor za error
  export const selectError = createSelector(
    [selectAuthState],
    (auth) => auth.error
  );
  export const selectUser = createSelector(
    [selectAuthState],
    (auth) => auth.user
  );
 
// Funkcija za proveru validnosti tokena
export const checkTokenValidity = (token) => {
  if (!token) return false;
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    // Ako token nema exp vreme, smatramo ga validnim
    if (!payload.exp) return true;
    const expirationTime = payload.exp * 1000; // Konvertuj u milisekunde
    return Date.now() < expirationTime;
  } catch (error) {
    console.error('Token validation error:', error);
    return false;
  }
};

const initialState = {
    user: null,
    error: "",
    loading: false
};

// Proveri token pri inicijalizaciji
const storedProfile = localStorage.getItem("Profile");
if (storedProfile) {
  const { token } = JSON.parse(storedProfile);
  if (!checkTokenValidity(token)) {
    localStorage.removeItem("Profile");
    initialState.user = null;
  } else {
    initialState.user = JSON.parse(storedProfile);
  }
}

export const login = createAsyncThunk(
  "auth/login",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await signin(formData);
      const { result, token } = response.data;
      
      if (!checkTokenValidity(token)) {
        throw new Error("Invalid token");
      }
      return { result, token };
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: "Login failed" });
    }
  }
);

export const register = createAsyncThunk("auth/register", async({formValue, navigate, toast},{rejectWithValue})=>{
    try {
        const response = await api.signup(formValue);
        alert('You need to verify your email first before logging in.')
        return response.data;
    } catch (error) {
      toast.error('User already exists')
        return rejectWithValue(error.response.data);
    }
})
export const editProfile = createAsyncThunk(
  "auth/editProfile",
  async ({ formData, toast }, { rejectWithValue }) => {
    try {
      const response = await api.updateProfile(formData);
      const { result, token } = response.data;
      
      // Update local storage with new data
      localStorage.setItem("Profile", JSON.stringify({ result, token }));
      
      toast.success("Profile updated successfully!");
      return { result, token };
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to update profile";
      toast.error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers: {
      setUser: (state, action) =>{
        state.user = action.payload;
   },
        clearError: (state) => {
          state.error = null; // Clear the error
        },
        logout: (state) => {
            localStorage.clear();
            state.user = null;
        }
      },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                localStorage.setItem("Profile", JSON.stringify(action.payload));
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
            })
            .addCase(register.pending, (state) => {
                state.loading = true;
            })
            .addCase(register.fulfilled, (state, action) => {
                state.loading = false;
                localStorage.setItem("Profile", JSON.stringify({ ...action.payload }));
                state.user = action.payload;
            })
            .addCase(register.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message;
                state.error = null;
            })
            .addCase(editProfile.fulfilled, (state, action) => {
                state.user = action.payload;
            })
            .addCase(editProfile.rejected, (state, action) => {
                state.error = action.payload;
            });
    }
});
export const { clearError, logout, setUser } = authSlice.actions;
export default authSlice.reducer;