import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit, faTrash, faEye } from '@fortawesome/free-solid-svg-icons';
import ServiceData from './ServiceData';
import { deleteOneService, getUserServices } from '../redux/features/serviceSlice';
import { toast } from 'react-toastify';

const Services = ({services}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const userId = user?.result?._id;
  const [showData, setShowData] = useState(false);
  const [serviceData, setServiceData] = useState(null);
  
  useEffect(() => {
    // Log services for debugging
    if (services) {
      console.log("Services component received:", services);
    }
  }, [services]);

  const openShowData = (id) => {
    const selectedService = services.find(service => service._id === id);
    if (selectedService) {
      console.log("Selected service for view:", selectedService);
      setServiceData(selectedService); 
      setShowData(true);
    } else {
      console.error("Service not found:", id);
    }
  }
  
  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      dispatch(deleteOneService({id, toast}))
        .then(() => {
          if (userId) {
            // Refresh the services list after deletion
            dispatch(getUserServices({userId}));
          }
        });
    }
  }

  if (!services || !Array.isArray(services)) {
    console.error("Services prop is invalid:", services);
    return <p className="text-white">No services available</p>;
  }
  
  return (
    <div>
      {services.length > 0 ? (
        <ul className="flex flex-col md:w-1/2">
          {services.map((item, index) => (
            <li
              key={item._id || index}
              className="flex flex-row justify-between font-bold border border-black my-1"
              style={{ 
                backgroundColor: item.colorFinish || '#f0f0f0', 
                padding: '6px',
                borderRadius: '4px'
              }}
            >
              <div>
                {index + 1}. {item.service} 
                <span className="text-xs ml-2">
                  {item.category && `(${item.category})`}
                </span>
              </div>
              <div className="flex flex-row">
                <button className="text-sm hover:text-base hover:text-gray-900 mx-1">
                  <Link to={`/editService/${item._id}`}>
                    <FontAwesomeIcon icon={faEdit} />
                  </Link>
                </button>
                <button
                  className="text-sm hover:text-base hover:text-gray-900 mx-1"
                  onClick={() => handleDelete(item._id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
                <button
                  className="text-sm hover:text-base hover:text-gray-900 mx-1"
                  onClick={() => openShowData(item._id)}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-white">No services available</p>
      )}
      
      {showData && serviceData && (
        <ServiceData serviceData={serviceData} closePopup={() => setShowData(false)}/>
      )}
    </div>
  )
}

export default Services;