import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector,useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { faEdit, faTrash, faSearch, faBars } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { deleteClient } from '../redux/features/clientSlice';
import ClientData from './ClientData';
import Pagination from '../components/Pagination';
import ClientsHistory from './ClientsHistory';
import ClientFiltered from './ClientFiltered';
import { useTranslation } from 'react-i18next';

const Clients = ({ data, cardPhone, setClientHistory}) => {  
  console.log(cardPhone);
  
  const [searchTerm, setSearchTerm] = useState('');
  const [showData, setShowData] = useState(false);
  const [clientData, setClientData] = useState();
  const [cardFilter, setCardFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const clientsPerPage = 10;
  const totalPages = Math.ceil(data.length / clientsPerPage);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDelete = (id) =>{
if(window.confirm('Are you sure you want to delete this client?',id)){
    dispatch(deleteClient({id, toast}))
}
  }
  const openShowData = (id)=>{
    const selectedClient = data.find(client => client._id === id);
    setClientData(selectedClient); 
    setShowData(true); 
  }

  const takeClientHistory = (phone) => {
    setClientHistory(phone);
  }

  useEffect(()=>{
    if(cardPhone){
      setCardFilter(true)
     };
  },[])
   
  

  const filteredClients = data.filter(client => {
    const searchString = searchTerm.toLowerCase();
    const fullName = `${client.firstName || ''} ${client.lastName || ''}`.toLowerCase();
    return (
      client.firstName?.toLowerCase().includes(searchString) ||
      client.lastName?.toLowerCase().includes(searchString) ||
      fullName.includes(searchString) ||
      client.email?.toLowerCase().includes(searchString) ||
      client.phone?.toLowerCase().includes(searchString)
    );
  });

  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = data.slice(indexOfFirstClient, indexOfLastClient);

  return (
    <div className="">
      <div className="flex flex-row justify-between">
      <h2 className="text-xl font-bold text-white mb-1">{t('clients.title')}</h2>
      <div className="relative ml-auto">
          <input
            type="text"
            placeholder={t('clients.searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-1 pl-8 rounded-lg bg-white text-xs text-gray-800 border border-gray-300 w-[150px]"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-500"
          />
        </div>
      </div>
      <div className="overflow-x-auto w-full">
      <div className=''>{cardPhone && <ClientFiltered cardPhone={cardPhone} />}</div>
        <table className="min-w-full bg-transparent rounded-lg shadow-lg">
          <tbody className=''>
          {searchTerm !== '' && filteredClients.length > 0 ? (
              filteredClients
                .slice()
                .sort((a, b) => {
                  const nameA = a.lastName || a.firstName || '';
                  const nameB = b.lastName || b.firstName || '';
                  return nameA.localeCompare(nameB);
                })
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-b border-transparent last:border-none text-sm border border-black bg-green-900">
                      <td className="px-1 py-1 text-white text-sm">{index + 1}</td>
                      <td className="px-1 py-1 text-white text-sm">
                        {item.firstName} {item.lastName}
                      </td>
                      <td className="px-1 py-1 text-white text-sm">{item.phone}</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="px-1 py-1">
                        <div className="flex flex-row justify-center">
                          <button className="text-sm hover:text-base mx-6 hover:text-gray-900">
                            <Link to={`/editClient/${item._id}`}>
                              <FontAwesomeIcon className='text-white' icon={faEdit} />
                            </Link>
                          </button>
                          <button 
                            className="text-sm hover:text-base mx-6 hover:text-gray-900" 
                            onClick={() => handleDelete(item._id)}
                          >
                            <FontAwesomeIcon className='text-white' icon={faTrash} />
                          </button>
                          <button 
                            className="text-sm text-white hover:text-base mx-6 hover:text-gray-900" 
                            onClick={() => openShowData(item._id)}
                          >
                            <FontAwesomeIcon icon={faBars} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center px-1 py-2 text-white">
                  {searchTerm !== '' ? t('clients.noResults') : ''}
                </td>
              </tr>
            )}
            {searchTerm === '' && data.length > 0 ? (
              data
                .slice()
                .sort((a, b) => {
                  const lastNameA = a.lastName || '';
                  const lastNameB = b.lastName || '';
                  return lastNameA.localeCompare(lastNameB);
                })
                .map((item, index) => (
                  <React.Fragment key={index}>
                    <tr className="border-b border-transparent last:border-none text-sm">
                      <td className="text-white text-sm">{item.firstName} {item.lastName}</td>
                      <td className="text-white text-sm">{item.phone}</td>
                    </tr>
                    <tr>
                      <td colSpan="2" className="px-1 py-1">
                        <div className="flex flex-row justify-center">
                          <button className="text-sm hover:text-base mx-6 hover:text-gray-900">
                            <Link to={`/editClient/${item._id}`}>
                              <FontAwesomeIcon className='text-white' icon={faEdit} />
                            </Link>
                          </button>
                          <button className="text-sm hover:text-base mx-6 hover:text-gray-900" onClick={() => handleDelete(item._id)}>
                            <FontAwesomeIcon className='text-white' icon={faTrash} />
                          </button>
                          <button className="text-sm text-white hover:text-base mx-6 hover:text-gray-900" onClick={() => openShowData(item._id)}>
                            <FontAwesomeIcon icon={faBars} />
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <hr className="border-t border-white my-1" />
                      </td>
                    </tr>
                  </React.Fragment>
                ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center px-1 py-2 text-white">
                </td>
              </tr>
            )}
              {/* <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={(page) => setCurrentPage(page)}
      />*/}
          </tbody>
        </table>
      </div>
      {showData && (
        <ClientData 
          clientData={clientData} 
          closePopup={() => setShowData(false)}
          takeClientHistory={takeClientHistory}
        />
      )}


    </div>
  );
};

export default Clients;
