import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit,faTimes } from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';

const ServiceData = ({serviceData, closePopup}) =>  {
    const user = useSelector(selectUser);
    const formattedDate = new Date(serviceData.createdAt).toLocaleDateString('sr-RS', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
    const formatPrice = (value) => {
      const numericValue = value.replace(/[^0-9,]/g, '');
      const parts = numericValue.split(',');
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.'); 
      if (parts[1] === undefined) {
        return `${integerPart},00`; 
      } else {
        return `${integerPart},${parts[1].slice(0, 2)}`; 
      }
    };
    const formattedPrice = serviceData.price 
  ? formatPrice(serviceData.price.toString().replace('.', ',')) 
  : "0,00";
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div 
        className="bg-white rounded-sm shadow-lg p-6 w-11/12 md:w-1/3"
        onClick={(e) => e.stopPropagation()} // Sprečava zatvaranje kada se klikne unutar popupa
      >
        <h2 className="text-xl font-bold mb-4">All Service Data</h2>
        <div className="min-w-full bg-transparent rounded-sm shadow-lg bg-gray-50">
        <ul className="border-b border-transparent last:border-none">
        <li className="px-2 py-1 text-black">Name of service : <b>{serviceData.service}</b> </li><hr/>
        <li className="px-2 py-1 text-black">Category of service : <b>{serviceData.category}</b> </li><hr/>
        <li className="px-2 py-1 text-black">Price of service : <b>{formattedPrice}</b></li><hr/>
        <li className="px-2 py-1 text-black">Time of service : <b>{serviceData.time}</b> min.</li><hr/>
        <li className="px-2 py-1 text-black"><i>Created <b className='text-sm'>{formattedDate}</b> by  <b className='text-sm'>{user?.result?.email}</b></i></li><hr/>
        </ul>
        </div>
     
        <div className='flex justify-end'>
        <button 
          onClick={closePopup} 
          className="mt-4 px-2 py-1 bg-black text-white rounded-sm"
        >
        <FontAwesomeIcon className='p-2' icon={faTimes} />

        </button>
        </div>
     
       
      </div>
    </div>
    )
  }

export default ServiceData