import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { faPhone, faEnvelope, faEdit, faTimes, faClock, faUserTie, faUser, faMoneyBill, faBrush, faFileText} from '@fortawesome/free-solid-svg-icons';
import { selectUser } from '../redux/features/authSlice';
import { Link } from 'react-router-dom';

const ClientData = ({archives, archiveImages, isLoading, closePopup}) => {
  const [showGalery, setShowGalery] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const user = useSelector(selectUser);

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeBackdrop = () => {
    setSelectedImage(null);
  };

  const toggleGallery = () => {
    setShowGalery(!showGalery);
  };

  const [clientShow, setClientShow] = useState(false);
  
  const clientHistory = () => {
    closePopup();
  }

  const formattedDate = new Date(archives.createdAt).toLocaleDateString('sr-RS', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex text-sm justify-center items-center z-50">
      <div 
        className="bg-white rounded-sm shadow-lg p-6 w-11/12 md:w-1/3"
        onClick={(e) => e.stopPropagation()} 
      >
        <h2 className="text-xl font-bold mb-4">All Session Data</h2>
        <div className="min-w-full bg-transparent rounded-sm shadow-lg bg-gray-50">
          <ul className="border-b border-transparent last:border-none">
            <div className='flex flex-row justify-between'> 
              <li className="px-2 py-1 text-black">{formattedDate} | {archives.time}</li>
              <FontAwesomeIcon className='p-2' icon={faClock} />
            </div>  
            <hr/>
            <div className='flex flex-row justify-between'> 
              <li className="px-2 py-1 text-black">{archives.firstName} {archives.lastName}</li>
              <FontAwesomeIcon className='p-2' icon={faUser} />
            </div>
            <hr/>
            <div className='flex flex-row justify-between'>  
              <li className="px-2 py-1 text-black">{archives.service}</li>
              <FontAwesomeIcon className='p-2' icon={faBrush} />
            </div>
            <hr/>
            <div className='flex flex-row justify-between'> 
              <li className="px-2 py-1 text-black">{archives.price}</li>
              <FontAwesomeIcon className='p-2' icon={faMoneyBill} />
            </div>
            <hr/>
            <div className='flex flex-row justify-between'>
              <li className="px-2 py-1 text-black">{archives.phone}</li>
              <a href={`tel:${archives.phone}`} >
                <FontAwesomeIcon className='p-2' icon={faPhone} />
              </a>
            </div>
            <hr/>
            <div className='flex flex-row justify-between'>
              <li className="px-2 py-1 text-black">{archives.email}</li>
              <a href={`mailto:${archives.email}`} >
                <FontAwesomeIcon className='p-2' icon={faEnvelope} />
              </a>
            </div>
            <hr/>
            <div className='flex flex-row justify-between'>
              <li className="px-2 py-1 text-black">{archives.workerFirstName} {archives.workerLastName}</li>
              <FontAwesomeIcon className='p-2' icon={faUserTie} />
            </div>
            <div className='flex flex-row justify-between'>
              <li className="px-2 py-1 text-black">{archives.comment}</li>
              <FontAwesomeIcon className='p-2' icon={faFileText} />
            </div>
            <hr/>
            <li className="px-2 py-1 text-black">
              <button className='border border-black px-2 rounded-md' onClick={toggleGallery}>
                {showGalery ? "Sakrij slike" : "Prikaži slike"}
              </button>
              {showGalery && (
                <div className="flex justify-center items-center mt-4">
                  {isLoading ? (
                    <div className="flex items-center justify-center p-4">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-600"></div>
                    </div>
                  ) : (
                    archiveImages ? (
                      <div className="flex flex-col">
                        <div className="flex flex-row justify-center">
                          {archiveImages.image1 && 
                            <img 
                              src={archiveImages.image1} 
                              className='p-1 w-[100px] h-[120px] object-cover' 
                              onClick={() => handleImageClick(archiveImages.image1)} 
                              alt="Image 1" 
                            />
                          }
                          {archiveImages.image2 && 
                            <img 
                              src={archiveImages.image2} 
                              className='p-1 w-[100px] h-[120px] object-cover' 
                              onClick={() => handleImageClick(archiveImages.image2)} 
                              alt="Image 2" 
                            />
                          }
                        </div>
                        <div className="flex flex-row justify-center">
                          {archiveImages.image3 && 
                            <img 
                              src={archiveImages.image3} 
                              className='p-1 w-[100px] h-[120px] object-cover' 
                              onClick={() => handleImageClick(archiveImages.image3)} 
                              alt="Image 3" 
                            />
                          }
                          {archiveImages.image4 && 
                            <img 
                              src={archiveImages.image4} 
                              className='p-1 w-[100px] h-[120px] object-cover' 
                              onClick={() => handleImageClick(archiveImages.image4)} 
                              alt="Image 4" 
                            />
                          }
                        </div>
                        {(!archiveImages.image1 && !archiveImages.image2 && !archiveImages.image3 && !archiveImages.image4) && (
                          <div className="text-gray-500">Nema dostupnih slika</div>
                        )}
                      </div>
                    ) : (
                      <div className="text-gray-500">Nema dostupnih slika</div>
                    )
                  )}
                </div>
              )}
            </li>
            <hr/>
            {selectedImage && (
              <div
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
                onClick={closeBackdrop}
              >
                <div className="relative w-[80%] h-[80%]">
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="w-full h-full object-contain"
                  />
                  <button
                    className="absolute top-2 right-2 bg-white py-2 px-4 font-bold shadow"
                    onClick={closeBackdrop}
                  >
                    x
                  </button>
                </div>
              </div>
            )}
            <li className="px-2 py-1 text-black">
              <i>Archived <b className='text-sm'>{formattedDate}</b> by <b className='text-sm'>{user?.result?.email}</b></i>
            </li>
            <hr/>
            <div className='flex justify-center space-x-2'>
              <Link 
                to={`/editArchived/${archives._id}`}
                className="mt-4 px-2 py-1 bg-black text-white rounded-sm"
              >
                <FontAwesomeIcon className='p-2' icon={faEdit} />
              </Link>
              <button 
                onClick={closePopup} 
                className="mt-4 px-2 py-1 bg-black text-white rounded-sm"
              >
                <FontAwesomeIcon className='p-2' icon={faTimes} />
              </button>
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ClientData