import React, { useReducer } from 'react';
import SendMail from './mail/SendMail.js';
import DownloadCSV from '../components/DownloadContacts.js';
import Csv from './download/Csv.js';

// Početno stanje
const initialState = {
  view: 'sms', // default view
};

// Reducer funkcija
function viewReducer(state, action) {
  switch (action.type) {
    case 'SET_VIEW':
      return { ...state, view: action.payload };
    default:
      return state;
  }
}

const BulkNotifications = () => {
  const [state, dispatch] = useReducer(viewReducer, initialState);

  const handleViewChange = (view) => {
    dispatch({ type: 'SET_VIEW', payload: view });
  };

  return (
    <div className="flex flex-col justify-between">
      <h2 className="text-xl font-bold text-white mb-1 mt-4">
        Bulk notifications
      </h2>
      
      {/* Dugmad za promenu view-a */}
      <div className="flex space-x-2 mb-4">
        <button 
          onClick={() => handleViewChange('sms')}
          className={`px-4 py-2 rounded ${state.view === 'sms' ? 'bg-black text-white' : 'bg-gray-200 text-black'}`}
        >
          SMS
        </button>
        <button 
          onClick={() => handleViewChange('mail')}
          className={`px-4 py-2 rounded ${state.view === 'mail' ? 'bg-black text-white' : 'bg-gray-200 text-black'}`}
        >
          Mail
        </button>
        <button 
          onClick={() => handleViewChange('excel')}
          className={`px-4 py-2 rounded ${state.view === 'excel' ? 'bg-black text-white' : 'bg-gray-200 text-black'}`}
        >
          Excel file
        </button>
      </div>

      {/* Prikaz trenutnog view-a */}
      <div>
        {state.view === 'sms' && (
          <div>
            <p>Prikaz SMS view-a</p>
            {/* Ovde dodaj komponente ili logiku za SMS notifikacije */}
          </div>
        )}
        {state.view === 'mail' && (
          <div>
            <SendMail />
            {/* Ovde dodaj komponente ili logiku za Mail notifikacije */}
          </div>
        )}
        {state.view === 'excel' && (
          <div>
            <Csv />
            {/* Ovde dodaj komponente za ostale notifikacije */}
          </div>
        )}
      </div>
    </div>
  );
};

export default BulkNotifications;
