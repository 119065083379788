import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ColorDropdown from "../components/ColorDropdown";
import { useDispatch } from "react-redux";
import { updateWorker, deleteOneWorker } from "../redux/features/workerSlice";
import { toast } from "react-toastify";

const Workers = ({ workers }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    color: "darkgray"
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showEditForm && selectedWorker) {
      dispatch(updateWorker({ id: selectedWorker._id, updatedWorkerData: formData }))
        .then(() => {
          setShowEditForm(false);
          setSelectedWorker(null);
          toast.success(t('workers.updateSuccess'));
        })
        .catch((error) => {
          console.error("Update error:", error);
          toast.error(t('workers.updateError'));
        });
    }
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      color: "darkgray"
    });
  };

  const handleEdit = (worker) => {
    console.log("Editing worker:", worker);
    setSelectedWorker(worker);
    setFormData({
      firstName: worker.firstName || "",
      lastName: worker.lastName || "",
      email: worker.email || "",
      phone: worker.phone || "",
      color: worker.color || "darkgray"
    });
    setShowEditForm(true);
  };

  const handleDelete = (worker) => {
    setSelectedWorker(worker);
    setShowDeleteConfirm(true);
  };

  const confirmDelete = () => {
    if (selectedWorker) {
      dispatch(deleteOneWorker({id: selectedWorker._id, toast}))
        .then(() => {
          setShowDeleteConfirm(false);
          setSelectedWorker(null);
          toast.success(t('workers.deleteSuccess'));
        })
        .catch((error) => {
          toast.error(t('workers.deleteError'));
        });
    }
  };

  return (
    <div className="space-y-4">
      {showEditForm && selectedWorker && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">{t('workers.editWorker')}</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('workers.firstName')}
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 border rounded"
                  placeholder={t('workers.enterFirstName')}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('workers.lastName')}
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  className="w-full px-3 py-2 border rounded"
                  placeholder={t('workers.enterLastName')}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('workers.email')}
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                  placeholder={t('workers.enterEmail')}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('workers.phone')}
                </label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                  placeholder={t('workers.enterPhone')}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  {t('workers.color')}
                </label>
                <ColorDropdown
                  oldColor={formData.color}
                  forwardColor={(color) => setFormData(prev => ({ ...prev, color }))}
                />
              </div>
              <div className="flex gap-2 justify-end mt-4">
                <button
                  type="submit"
                  className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800"
                >
                  {t('workers.submit')}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setShowEditForm(false);
                    setSelectedWorker(null);
                  }}
                  className="bg-white text-black border border-black px-4 py-2 rounded hover:bg-gray-100"
                >
                  {t('common.cancel')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {workers.map((worker) => (
          <div
            key={worker._id}
            className="bg-white p-4 rounded shadow"
            style={{ borderLeft: `4px solid ${worker.color || 'darkgray'}` }}
          >
            <h3 className="text-lg font-semibold mb-2">
              {worker.firstName} {worker.lastName}
            </h3>
            <div className="text-sm space-y-1">
              <div className="flex items-center">
                <span className="font-medium mr-2">{t('workers.email')}:</span>
                <span className="text-gray-600">{worker.email || '-'}</span>
              </div>
              <div className="flex items-center">
                <span className="font-medium mr-2">{t('workers.phone')}:</span>
                <span className="text-gray-600">{worker.phone || '-'}</span>
              </div>
              <div className="flex items-center">
                <span className="font-medium mr-2">{t('workers.color')}:</span>
                <div className="w-4 h-4 rounded-full" style={{ backgroundColor: worker.color || 'darkgray' }}></div>
              </div>
            </div>
            <div className="mt-4 flex gap-2">
              <button
                onClick={() => handleEdit(worker)}
                className="bg-black text-white px-4 py-2 rounded hover:bg-gray-800"
              >
                {t('workers.editWorker')}
              </button>
              <button
                onClick={() => handleDelete(worker)}
                className="bg-white text-black border border-black px-4 py-2 rounded hover:bg-gray-100"
              >
                {t('common.delete')}
              </button>
            </div>
          </div>
        ))}
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg">
            <p className="mb-4">{t('workers.confirmDelete')}</p>
            <div className="flex gap-2 justify-end">
              <button
                onClick={confirmDelete}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                {t('common.delete')}
              </button>
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="bg-white text-black border border-black px-4 py-2 rounded hover:bg-gray-100"
              >
                {t('common.cancel')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Workers;