import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { createWorker } from '../redux/features/workerSlice';
import Workers from '../lists/Workers';
import ColorDropdown from '../components/ColorDropdown';
import { useTranslation } from 'react-i18next';

const initialState = {
  firstName:'',
  lastName:'',
  color:'',
  email:'',
  phone:''
}
const AddWorker = ({goToAddSession, workers})=> {
  
  const [workerData, setWorkerData] = useState(initialState);
  const dispatch  = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

 const onInputChange = (e)=>{
  const {name, value} = e.target;
        setWorkerData({...workerData, [name]: value})
 };
 const takeColor = (color) => {
  setWorkerData(prevState => ({
    ...prevState,
    color: color
  }));
};

 const handleSubmit = (e) =>{
  e.preventDefault();
  const workerToSubmit = {
    firstName: workerData.firstName,
    lastName: workerData.lastName,
    email: workerData.email,
    phone: workerData.phone,
    color: workerData.color
  };
  
  dispatch(createWorker({workerData: workerToSubmit, navigate, toast}));
  goToAddSession();
 }
  return (
    <div className="w-full max-w-4xl mx-auto p-4">
        <h2 className="text-xl font-bold text-white mb-6">{t('workers.addWorker')}</h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full" noValidate>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                    <label className="block text-sm font-medium text-gray-100 mb-1">{t('workers.firstName')}</label>
                    <input 
                        onChange={onInputChange} 
                        id="firstName" 
                        name="firstName" 
                        type="text" 
                        className="w-full px-3 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none bg-white" 
                        placeholder={t('workers.enterFirstName')} 
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-100 mb-1">{t('workers.lastName')}</label>
                    <input 
                        onChange={onInputChange} 
                        id="lastName" 
                        name="lastName" 
                        type="text" 
                        className="w-full px-3 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none bg-white" 
                        placeholder={t('workers.enterLastName')} 
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-100 mb-1">{t('workers.email')}</label>
                    <input 
                        onChange={onInputChange}
                        id="email"
                        name="email"
                        type="email"
                        value={workerData.email}
                        placeholder={t('workers.enterEmail')} 
                        className="w-full px-3 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none bg-white" 
                    />
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-100 mb-1">{t('workers.phone')}</label>
                    <input 
                        onChange={onInputChange}
                        id="phone"
                        name="phone"
                        type="tel"
                        value={workerData.phone}
                        placeholder={t('workers.enterPhone')} 
                        className="w-full px-3 py-2 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none bg-white" 
                    />
                </div>
                <div className="md:col-span-2">
                    <label className="block text-sm font-medium text-gray-100 mb-1">{t('workers.color')}</label>
                    <div className="w-full">
                        <ColorDropdown forwardColor={takeColor} />
                    </div>
                </div>
            </div>
            <div className="mt-6">
                <button 
                    type="submit" 
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400 transition-colors duration-200"
                >
                    {t('workers.submit')}
                </button>
            </div>
        </form>
        <div className="mt-8">
            <h5 className='text-white font-bold text-lg mb-4'>{t('workers.allWorkers')}</h5>
            <Workers workers={workers} />
        </div>
    </div>
  )
}

export default AddWorker;