import React, {useState} from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faPhone } from '@fortawesome/free-solid-svg-icons';
import ClientFilteredData from './ClientFilteredData.js';
import { useTranslation } from 'react-i18next';

const ClientFiltered = ({cardPhone}) => {
  const [selectedSession, setSelectedSession] = useState(null);
  const [showClientHistory, setShowClientHistory] = useState(false);
  const { t } = useTranslation();
  
  const archives = useSelector((state) => state.archive.archives);
  const sessions = useSelector((state) => state.session.sessions);
  const currentClient = sessions.find(session => session.phone === cardPhone);
  const filteredCard = archives.filter(item => item.phone === cardPhone);
  
  const showClientData = (sessionId) => {
    setSelectedSession(sessionId);
    setShowClientHistory(true);
  }
  
  const handleCloseModal = () => {
    setShowClientHistory(false);
    setSelectedSession(null);
  }
  
  return (
    <div className='w-full flex flex-col bg-white p-4'>
      <h2 className="text-xl font-bold text-green-800 mb-4">
        {currentClient ? `${currentClient.firstName} ${currentClient.lastName}` : cardPhone}
      </h2>
      
      {filteredCard.length > 0 ? (
        <ul className='mt-3'>
          {filteredCard
            .slice() 
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .map(item =>
              <div key={item._id} className="hover:bg-green-50">
                <li className="py-2">
                  {new Date(item.date).toLocaleDateString('sr-RS', {
                    day: '2-digit',
                    month: '2-digit',
                  })} {item.service}
                  <FontAwesomeIcon 
                    icon={faBars}  
                    onClick={() => showClientData(item._id)}  
                    className='float-right p-1 cursor-pointer hover:text-green-600'
                  />
                </li>
                <hr className="border-green-100"/>
              </div>
            )}
        </ul>
      ) : (
        <p className="text-gray-600 text-center py-4">{t('clients.noTreatmentsYet')}</p>
      )}
      
      {showClientHistory && (
        <ClientFilteredData 
          showClientCard={selectedSession} 
          onClose={handleCloseModal}
        />
      )}
    </div>
  )
}

export default ClientFiltered