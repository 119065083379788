import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from '../api.js';
import { useTranslation } from 'react-i18next';

export const createSMS = createAsyncThunk(
    "sms/createSMS",
    async ({smsData, navigate, toast}, {rejectWithValue}) =>{
        try {
            const response = await api.createSMS(smsData);
            toast.success('messageCreated');
            navigate('/admin');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
)

export const getSMS = createAsyncThunk(
    "sms/getSMS", async({userId},{rejectWithValue}) =>{
    try{
const response = await api.getSMS(userId);
        return response.data;
    }catch(err){
return rejectWithValue(err.response.data)    }
});

export const updateSMS = createAsyncThunk(
    "sms/updateSMS",
    async ({id, updatedSMSData, navigate, toast},{rejectWithValue})=>{
        try{
            const response = await api.updateSMS(id, updatedSMSData);
            toast.success('messageUpdated');
            navigate('/admin');
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)

export const sendSMSMessage = createAsyncThunk(
    "sms/sendSMSMessage",
    async ({id, toast},{rejectWithValue})=>{
        try{
            const response = await api.sendSMSMessage(id);
            toast.success('smsSent')
            return response.data;
        }catch(error){
            return rejectWithValue(error.response.data)
        }
    }
)
const smsSlice = createSlice({
    name:'sms',
    initialState:{
        error:'',
        loading:false,
        sms:[]
    },
    
    extraReducers: (builder) => {
        builder
            .addCase(createSMS.pending, (state) => {
                state.loading = true;
            })
            .addCase(createSMS.fulfilled, (state, action) => {
                state.loading = false;
                state.sms = [...state.sms, action.payload];
            })
            .addCase(createSMS.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
           .addCase(getSMS.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSMS.fulfilled, (state, action) => {
                state.loading = false;
                state.sms =  action.payload;
                
            })
            .addCase(getSMS.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message 
            })
            .addCase(updateSMS.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateSMS.fulfilled, (state, action) => {
                state.loading = false;
                const {arg : {id},} = action.meta;
                if(id){
                  state.sms = state.sms.map((item)=> item._id === id ? action.payload : item)
                }
            })
            .addCase(updateSMS.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })
        }
})
export default smsSlice.reducer;