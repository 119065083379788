import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const locales = {
    en: 'English',
    rs: 'Serbian',
    fr: 'French',
    it: 'Italian',
    es: 'Spanish'
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
        },
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/translation.json`
        }
    });

export default i18n;
