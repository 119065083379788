import React, { useState, useEffect } from "react";
import FinanceCategory from "./FinanceCategory";
import { useTranslation } from "react-i18next";

const Finance = ({ archives, categories, services, workers }) => {
  const { t } = useTranslation();
  const [categoryView, setCategoryView] = useState(false);
  const [workerView, setWorkerView] = useState(false);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [tempSelectedWorkers, setTempSelectedWorkers] = useState([]);
  const [tempSelectedCategories, setTempSelectedCategories] = useState([]);
  const [tempSelectedServices, setTempSelectedServices] = useState([]);
  const [isFiltering, setIsFiltering] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [servicesWithoutCategory, setServicesWithoutCategory] = useState([]);

  const getCurrentDate = () => {
    const today = new Date();
    // YYYY-MM-DD format
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  };

  const getPreviousMonthDate = () => {
    const today = new Date();
    const prevMonth = new Date(today);
    prevMonth.setMonth(today.getMonth() - 1);
    // YYYY-MM-DD format
    return `${prevMonth.getFullYear()}-${String(prevMonth.getMonth() + 1).padStart(2, '0')}-${String(prevMonth.getDate()).padStart(2, '0')}`;
  };

  // Postavlja realne datume bez zamene godine
  const [startDate, setStartDate] = useState(getPreviousMonthDate());
  const [endDate, setEndDate] = useState(getCurrentDate());

  // Debug informacije o datumima
  useEffect(() => {
    console.log("Inicijalni datumi: ", { startDate, endDate });
    console.log("Trenutni sistemski datum: ", new Date().toISOString());
  }, []);

  useEffect(() => {
    applyFilters();
  }, [archives, startDate, endDate, selectedCategories, selectedServices, selectedWorkers]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleWorkerChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setTempSelectedWorkers([...tempSelectedWorkers, value]);
    } else {
      setTempSelectedWorkers(tempSelectedWorkers.filter(worker => worker !== value));
    }
  };

  const handleConfirmFilters = () => {
    console.log("Potvrđujem filtere:", {
      kategorije: tempSelectedCategories,
      servisi: tempSelectedServices,
      radnici: tempSelectedWorkers
    });
    setSelectedWorkers(tempSelectedWorkers);
    setSelectedCategories(tempSelectedCategories);
    setSelectedServices(tempSelectedServices);
    setIsFiltering(true);
    setCategoryView(false);
    setWorkerView(false);
  };

  const handleResetFilters = () => {
    setSelectedWorkers([]);
    setSelectedCategories([]);
    setSelectedServices([]);
    setTempSelectedWorkers([]);
    setTempSelectedCategories([]);
    setTempSelectedServices([]);
    setCategoryView(false);
    setWorkerView(false);
  };

  const applyFilters = () => {
    console.log("Applying filters with:");
    console.log("Date range:", startDate, "-", endDate);
    console.log("Categories:", selectedCategories);
    console.log("Services:", selectedServices);
    console.log("Workers:", selectedWorkers);
    console.log("Archives:", archives?.length);

    if (!archives || archives.length === 0) {
      setFilteredData([]);
      return;
    }

    // Brojači za statistiku
    const stats = {
      total: archives.length,
      withoutDate: 0,
      invalidDate: 0,
      outsideDateRange: 0,
      failedCategoryFilter: 0,
      failedServiceFilter: 0, 
      failedWorkerFilter: 0,
      passed: 0
    };

    // Za debug - prikaži sve dostupne podatke
    const allCategories = [...new Set(archives.filter(a => a.category).map(a => a.category))];
    const allServices = [...new Set(archives.filter(a => a.service).map(a => a.service))];
    const noCategServices = allServices.filter(service =>
      archives.some(a => a.service === service && !a.category)
    );
    const allWorkers = [...new Set(archives
      .filter(a => a.workerFirstName || a.workerLastName)
      .map(a => `${a.workerFirstName || ''} ${a.workerLastName || ''}`.trim())
    )];
    
    // Postavimo servise bez kategorije u state
    setServicesWithoutCategory(noCategServices);
    
    console.log("Dostupne kategorije:", allCategories);
    console.log("Dostupni servisi:", allServices);
    console.log("Servisi bez kategorije:", noCategServices);
    console.log("Dostupni radnici:", allWorkers);

    // Da bismo izbegli problem sa 2025. godinom, proverimo da li imamo podatke u 2024.
    const years = [...new Set(
      archives
        .filter(a => a.date)
        .map(a => new Date(a.date).getFullYear())
    )].sort();
    
    console.log("Godine u podacima:", years);

    const filtered = archives.filter((item) => {
      // Brojimo svaku stavku koju pokušavamo da filtriramo
      
      // Proveri da li item i datum postoje
      if (!item || !item.date) {
        console.log("Item without date:", item?._id, item);
        stats.withoutDate++;
        return false;
      }

      // Datum filter
      const itemDate = new Date(item.date);
      if (isNaN(itemDate.getTime())) {
        console.log("Invalid date:", item.date, "for item:", item._id);
        stats.invalidDate++;
        return false;
      }

      // Debug informacija o datumu stavke
      const dateInfo = {
        original: item.date,
        parsed: itemDate.toISOString(),
        year: itemDate.getFullYear(),
        month: itemDate.getMonth() + 1,
        day: itemDate.getDate()
      };
      
      // Formatirani datum za poređenje - proveramo da li je format ispravan
      const year = itemDate.getFullYear();
      const month = String(itemDate.getMonth() + 1).padStart(2, '0');
      const day = String(itemDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;

      // Logujemo format datuma i uporedimo sa filterima
      console.log("Item date:", dateInfo, "Formatted:", formattedDate, "Start:", startDate, "End:", endDate);
      
      // Standardno poređenje datuma
      const isInDateRange = formattedDate >= startDate && formattedDate <= endDate;
      if (!isInDateRange) {
        stats.outsideDateRange++;
        return false;
      }

      // Ako korisnik nije selektovao ni jednu kategoriju, prihvatamo sve
      if (selectedCategories.length > 0) {
        // Prvo proveravamo da li je servis selektovan - ako jeste, ignorišemo filter kategorije
        if (selectedServices.length > 0 && item.service && selectedServices.includes(item.service)) {
          console.log("Item sa selektovanim servisom:", item.service, "ignorišem filter kategorije");
          // Nastavljamo filtriranje, preskačemo proveru kategorije
        }
        // Ako servis nije selektovan, proveravamo kategoriju
        else {
          // Ako nema kategoriju, ali ima servis koji je selektovan, prihvatamo ga
          if (!item.category) {
            console.log("Item bez kategorije:", item._id, item.service);
            stats.failedCategoryFilter++;
            return false;
          } 
          // Ako ima kategoriju, proveravamo da li je selektovana
          else if (!selectedCategories.includes(item.category)) {
            console.log("Item kategorija nije selektovana:", item.category, "Item:", item._id);
            stats.failedCategoryFilter++;
            return false;
          }
        }
      }

      // Ako korisnik nije selektovao ni jedan servis, prihvatamo sve
      if (selectedServices.length > 0) {
        if (!item.service) {
          console.log("Item bez servisa:", item._id);
          stats.failedServiceFilter++;
          return false;
        }
        
        if (!selectedServices.includes(item.service)) {
          console.log("Item servis nije selektovan:", item.service, "Item:", item._id);
          stats.failedServiceFilter++;
          return false;
        }
      }

      // Ako korisnik nije selektovao ni jednog radnika, prihvatamo sve
      if (selectedWorkers.length > 0) {
        const workerFullName = `${item.workerFirstName || ''} ${item.workerLastName || ''}`.trim();
        if (!workerFullName) {
          console.log("Item without worker name:", item._id);
          stats.failedWorkerFilter++;
          return false;
        }
        
        if (!selectedWorkers.includes(workerFullName)) {
          console.log("Worker not selected:", workerFullName, "Item:", item._id);
          stats.failedWorkerFilter++;
          return false;
        }
      }

      stats.passed++;
      return true;
    });

    console.log("Filter stats:", stats);
    console.log("Filtered data length:", filtered.length);
    
    // Sortiraj po datumu
    const sortedData = [...filtered].sort((a, b) => new Date(a.date) - new Date(b.date));
    setFilteredData(sortedData);
  };

  const totalPrice = filteredData.reduce((sum, archive) => {
    const price = Number(archive.price || 0);
    return isNaN(price) ? sum : sum + price;
  }, 0);

  return (
    <div>
      <h2 className="text-xl font-bold text-white mb-1">{t('common.finance.title')}</h2>

      <div className="flex flex-row gap-2 text-xs">
        <div className="w-1/2">
          <label className="text-white font-bold">{t('common.finance.startDate')}:</label>
          <input type="date" value={startDate} onChange={handleStartDateChange} className="border border-gray-300 w-full" />
        </div>
        <div className="w-1/2">
          <label className="text-white font-bold">{t('common.finance.endDate')}:</label>
          <input type="date" value={endDate} onChange={handleEndDateChange} className="border border-gray-300 w-full" />
        </div>
      </div>

      <p className="text-center text-xs text-white font-bold mt-2">{t('common.finance.filtersBy')}</p>
      <div className="flex flex-row gap-2 text-xs">
        <div className="w-1/2">
          <button 
            className={`bg-white text-black w-full ${categoryView ? 'bg-green-500 text-white' : ''}`} 
            onClick={() => {
              setCategoryView(!categoryView);
              setWorkerView(false);
              setTempSelectedCategories([...selectedCategories]);
              setTempSelectedServices([...selectedServices]);
            }}
          >
            {t('common.finance.category')}
          </button>
        </div>
        <div className="w-1/2">
          <button 
            className={`bg-white text-black w-full ${workerView ? 'bg-green-500 text-white' : ''}`}
            onClick={() => {
              setWorkerView(!workerView);
              setCategoryView(false);
              setTempSelectedWorkers([...selectedWorkers]);
            }}
          >
            {t('common.finance.worker')}
          </button>
        </div>
      </div>
      
      {/* Dugme za prikaz svih podataka */}
      <div className="mt-2">
        <button 
          className="bg-blue-600 text-white text-xs w-full py-1 rounded-sm"
          onClick={() => {
            console.log("Prikazujem sve podatke");
            setSelectedWorkers([]);
            setSelectedCategories([]);
            setSelectedServices([]);
            setTempSelectedWorkers([]);
            setTempSelectedCategories([]);
            setTempSelectedServices([]);
            
            // Postaviti datumski opseg vrlo široko
            const today = new Date();
            const thisYear = today.getFullYear();
            setStartDate(`${thisYear-1}-01-01`); // Početak prethodne godine
            setEndDate(`${thisYear}-12-31`);     // Kraj tekuće godine
            
            setCategoryView(false);
            setWorkerView(false);
          }}
        >
          Prikaži sve podatke (dijagnostika)
        </button>
      </div>

      {categoryView && (
        <div>
          <FinanceCategory 
            categories={categories} 
            services={services}
            selectedCategories={tempSelectedCategories}
            setSelectedCategories={setTempSelectedCategories}
            selectedServices={tempSelectedServices}
            setSelectedServices={setTempSelectedServices}
          />
          
          {/* Prikaži servise bez kategorije ako postoje */}
          {servicesWithoutCategory && servicesWithoutCategory.length > 0 && (
            <div className="mt-4 border-t border-gray-600 pt-2">
              <p className="text-white font-bold">Servisi bez kategorije:</p>
              <div className="ml-4 max-h-32 overflow-y-auto">
                {servicesWithoutCategory.map((service, index) => (
                  <div key={`no-category-service-${index}`} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`no-category-service-${index}`}
                      value={service}
                      onChange={(e) => {
                        const { value, checked } = e.target;
                        console.log(`Servis bez kategorije ${value} ${checked ? 'checked' : 'unchecked'}`);
                        if (checked) {
                          if (!tempSelectedServices.includes(value)) {
                            setTempSelectedServices([...tempSelectedServices, value]);
                          }
                        } else {
                          setTempSelectedServices(tempSelectedServices.filter(s => s !== value));
                        }
                      }}
                      checked={tempSelectedServices.includes(service)}
                      className="mr-2"
                    />
                    <label htmlFor={`no-category-service-${index}`} className="text-white">
                      {service}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {workerView && (
        <div className="mt-2 max-h-32 overflow-y-auto">
          {workers && workers.length > 0 ? (
            workers.map((worker) => (
              <div key={worker._id} className="flex items-center">
                <input
                  type="checkbox"
                  id={`worker-${worker._id}`}
                  value={`${worker.firstName} ${worker.lastName}`}
                  onChange={handleWorkerChange}
                  checked={tempSelectedWorkers.includes(`${worker.firstName} ${worker.lastName}`)}
                  className="mr-2"
                />
                <label htmlFor={`worker-${worker._id}`} className="text-white">
                  {worker.firstName} {worker.lastName}
                </label>
              </div>
            ))
          ) : (
            <p className="text-white">Nema dostupnih radnika</p>
          )}
        </div>
      )}

      {(categoryView || workerView) && (
        <div className="flex flex-row gap-2 mt-2">
          <button 
            className="bg-green-500 text-white px-4 py-1 rounded w-1/2"
            onClick={handleConfirmFilters}
          >
            {t('common.finance.confirm')}
          </button>
          <button 
            className="bg-red-500 text-white px-4 py-1 rounded w-1/2"
            onClick={handleResetFilters}
          >
            {t('common.finance.reset')}
          </button>
        </div>
      )}

      <div className="mt-2">
        {isFiltering && (
          <div className="flex flex-col mb-2">
            <div className="flex flex-row justify-between items-center">
              <span className="text-white text-sm">{t('common.finance.activeFilters')}</span>
              {(selectedCategories.length > 0 || selectedServices.length > 0 || selectedWorkers.length > 0) && (
                <button 
                  className="bg-red-500 text-white px-2 py-1 rounded text-xs"
                  onClick={handleResetFilters}
                >
                  {t('common.finance.clearAllFilters')}
                </button>
              )}
            </div>
            <div className="mt-1 text-white text-xs">
              {selectedCategories.length > 0 && (
                <div className="mb-1">
                  <span className="font-bold">{t('common.finance.categories')}</span> {selectedCategories.join(", ")}
                </div>
              )}
              {selectedServices.length > 0 && (
                <div className="mb-1">
                  <span className="font-bold">{t('common.finance.services')}</span> {selectedServices.join(", ")}
                </div>
              )}
              {selectedWorkers.length > 0 && (
                <div className="mb-1">
                  <span className="font-bold">{t('common.finance.workers')}</span> {selectedWorkers.join(", ")}
                </div>
              )}
              <div>
                <span className="font-bold">{t('common.finance.dateRange')}</span> {new Date(startDate).toLocaleDateString("sr-RS")} - {new Date(endDate).toLocaleDateString("sr-RS")}
              </div>
            </div>
          </div>
        )}
        <ul>
          {filteredData.length > 0 ? (
            filteredData.map((archive, index) => (
              <div key={archive._id || index}>
                <li className="flex flex-row text-sm justify-between text-white">
                  {archive.date
                    ? new Date(archive.date).toLocaleDateString("sr-RS", { day: "2-digit", month: "2-digit" })
                    : "N/A"}
                  <span>{archive.firstName} {archive.lastName} ({archive.service})</span>
                  <span className="font-bold">{archive.price}</span>
                </li>
                <hr />
              </div>
            ))
          ) : (
            <p className="text-white text-center py-2">Nema podataka za izabrani period</p>
          )}
        </ul>
        <p className="float-right font-bold text-white">{t('common.finance.total')}: {totalPrice}</p>
      </div>
    </div>
  );
};

export default Finance;
