import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchArchiveImages } from '../redux/features/archiveSlice';
import { useTranslation } from 'react-i18next';

const ClientsHistoryData = ({clientData, archives, closeClientData}) => {
  const [showImages, setShowImages] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const archiveImages = useSelector(state => state.archive.archiveImages);
  const client = archives.find(item=>item._id === clientData);

  // Pratim promene u archiveImages za trenutnog klijenta
  useEffect(() => {
    if (client && archiveImages) {
      console.log('Svi archiveImages:', archiveImages);
      console.log('ID klijenta za slike:', client._id);
      
      if (archiveImages[client._id]) {
        console.log('Slike su učitane:', archiveImages[client._id]);
        
        // Sada je to objekat
        const archiveData = archiveImages[client._id];
        
        console.log('Tipovi slika:', {
          image1: typeof archiveData.image1,
          image2: typeof archiveData.image2,
          image3: typeof archiveData.image3,
          image4: typeof archiveData.image4
        });
        
        console.log('Sadržaj slika:', {
          image1: archiveData.image1 ? archiveData.image1.substring(0, 50) + '...' : null,
          image2: archiveData.image2 ? archiveData.image2.substring(0, 50) + '...' : null,
          image3: archiveData.image3 ? archiveData.image3.substring(0, 50) + '...' : null,
          image4: archiveData.image4 ? archiveData.image4.substring(0, 50) + '...' : null
        });
        
        setImagesLoaded(true);
      } else {
        console.log('Nema slika za ID:', client._id);
      }
    }
  }, [archiveImages, client]);

  const openShowData = async(id) => {
    console.log('Opening show data for ID:', id);
    setShowImages(!showImages);
    
    if (!archiveImages[id]) {
      setIsLoading(true);
      try {
        console.log('Fetching archive images...');
        await dispatch(fetchArchiveImages(id));
      } catch (error) {
        console.error("Error loading images:", error);
      } finally {
        setIsLoading(false);
      }
    }
  }

  if (!client) {
    console.log('No client found for ID:', clientData);
    return null;
  }

  const currentArchiveImages = archiveImages[client._id];
  console.log('Current archivedImages state:', currentArchiveImages);
  
  // Formatiraj datum
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('sr-RS', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <div className="text-white">
      <div className="flex flex-row justify-between">
        <h2 className="text-xl font-bold mb-1">{t('clients.allClientData')}</h2>
        <button onClick={closeClientData} className="text-white hover:text-gray-300">
          ✕
        </button>
      </div>
      <hr/>
      <div className="mt-2">
        {/* Detalji klijenta */}
        <div className="mb-4">
          <div className="flex flex-row justify-between py-1">
            <span className="font-bold">{client.firstName} {client.lastName}</span>
          </div>
          <div className="flex flex-row justify-between py-1">
            <span className="font-bold">{t('clients.phone')}:</span>
            <span>{client.phone}</span>
          </div>
          <div className="flex flex-row justify-between py-1">
            <span className="font-bold">{t('sessions.date')}:</span>
            <span>{formatDate(client.date)}</span>
          </div>
          <div className="flex flex-row justify-between py-1">
            <span className="font-bold">{t('sessions.time')}:</span>
            <span>{client.time}</span>
          </div>
          <div className="flex flex-row justify-between py-1">
            <span className="font-bold">{t('sessions.service')}:</span>
            <span>{client.service}</span>
          </div>
          <div className="flex flex-row justify-between py-1">
            <span className="font-bold">{t('sessions.price')}:</span>
            <span>{client.price}</span>
          </div>
          <div className="flex flex-row justify-between py-1">
            <span className="font-bold">{t('sessions.worker')}:</span>
            <span>{client.workerFirstName} {client.workerLastName}</span>
          </div>
          {client.comment && (
            <div className="mt-2">
              <div className="font-bold">{t('sessions.comment')}:</div>
              <div className="bg-white text-black p-2 rounded mt-1">{client.comment}</div>
            </div>
          )}
        </div>
        
        <div className="mt-4">
          <button 
            onClick={() => openShowData(client._id)}
            className="bg-green-900 text-white px-4 py-2 rounded"
          >
            {showImages ? t('common.hideImages') : t('common.showImages')}
          </button>
        </div>
        {isLoading && (
          <div className="flex justify-center items-center mt-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
          </div>
        )}
        {showImages && !isLoading && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            {currentArchiveImages ? (
              <>
                {currentArchiveImages.image1 && (
                  <div className="relative">
                    <img 
                      src={currentArchiveImages.image1} 
                      alt="Image 1"
                      className="w-full h-48 object-cover rounded"
                      onError={(e) => {
                        console.error('Error loading image1:', e);
                        console.log('Image1 value:', currentArchiveImages.image1);
                      }}
                    />
                  </div>
                )}
                {currentArchiveImages.image2 && (
                  <div className="relative">
                    <img 
                      src={currentArchiveImages.image2} 
                      alt="Image 2"
                      className="w-full h-48 object-cover rounded"
                      onError={(e) => {
                        console.error('Error loading image2:', e);
                        console.log('Image2 value:', currentArchiveImages.image2);
                      }}
                    />
                  </div>
                )}
                {currentArchiveImages.image3 && (
                  <div className="relative">
                    <img 
                      src={currentArchiveImages.image3} 
                      alt="Image 3"
                      className="w-full h-48 object-cover rounded"
                      onError={(e) => {
                        console.error('Error loading image3:', e);
                        console.log('Image3 value:', currentArchiveImages.image3);
                      }}
                    />
                  </div>
                )}
                {currentArchiveImages.image4 && (
                  <div className="relative">
                    <img 
                      src={currentArchiveImages.image4} 
                      alt="Image 4"
                      className="w-full h-48 object-cover rounded"
                      onError={(e) => {
                        console.error('Error loading image4:', e);
                        console.log('Image4 value:', currentArchiveImages.image4);
                      }}
                    />
                  </div>
                )}
                {(!currentArchiveImages.image1 && !currentArchiveImages.image2 && !currentArchiveImages.image3 && !currentArchiveImages.image4) ? (
                  <div className="col-span-2 text-center text-gray-400">
                    {t('common.noImages')}
                  </div>
                ) : null}
              </>
            ) : (
              <div className="col-span-2 text-center text-gray-400">
                {t('common.noImages')}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default ClientsHistoryData;