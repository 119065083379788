import React from "react";
import { getClientsCSV } from "../redux/api"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
const DownloadCSV = ({ userId }) => {
  const handleDownload = async () => {
    try {
      const response = await getClientsCSV(userId); 

      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "clients.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error("Greška pri preuzimanju CSV-a:", error);
    }
  };

  return <div className="flex flex-col">
    <p className="text-center">Preuzmi CSV (Only pro package)</p>
    <FontAwesomeIcon icon={faDownload} className="mt-3" onClick={handleDownload}/>
  </div>
};

export default DownloadCSV;
