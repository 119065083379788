import React, { useEffect } from 'react';

const FinanceCategory = ({ 
  categories, 
  services, 
  selectedCategories, 
  setSelectedCategories, 
  selectedServices, 
  setSelectedServices 
}) => {
  // Ažurira prikaz servisa kada se promene kategorije
  useEffect(() => {
    console.log("Selected categories changed:", selectedCategories);
    console.log("Selected services:", selectedServices);
  }, [selectedCategories, selectedServices]);

  // Ažuriranje odabranih kategorija
  const handleCategoryChange = (e) => {
    const { value, checked } = e.target;
    console.log(`Category ${value} ${checked ? 'checked' : 'unchecked'}`);

    if (checked) {
      // Dodaj kategoriju
      setSelectedCategories([...selectedCategories, value]);
      
      // Dodaj sve servise iz te kategorije
      const servicesInCategory = services
        .filter(service => service.category === value)
        .map(service => service.service);
      
      console.log("Servisi u kategoriji", value, ":", servicesInCategory);
      
      // Eliminiši duplikate
      const uniqueServices = [...new Set([...selectedServices, ...servicesInCategory])];
      setSelectedServices(uniqueServices);
    } else {
      // Ukloni kategoriju
      setSelectedCategories(selectedCategories.filter(category => category !== value));
      
      // Opciono: uklanjanje servisa iz kategorije
      // Ako želite zadržati servise i kada se kategorija ukine, zakomentarišite ovaj deo
      const servicesInCategory = services
        .filter(service => service.category === value)
        .map(service => service.service);
      
      console.log("Uklanjam servise iz kategorije", value, ":", servicesInCategory);
      
      // Ukloni servise iz te kategorije koji nisu u drugim kategorijama
      const newServices = selectedServices.filter(selectedService => {
        // Proveri da li se servis pojavljuje u drugoj kategoriji koja je selektovana
        const existsInOtherCategory = selectedCategories
          .filter(cat => cat !== value) // Isključi trenutnu kategoriju
          .some(cat => 
            services.some(s => 
              s.service === selectedService && s.category === cat
            )
          );
        
        // Zadrži servis ako postoji u drugoj selektovanoj kategoriji
        return existsInOtherCategory;
      });
      
      console.log("Novi selektovani servisi nakon uklanjanja kategorije:", newServices);
      setSelectedServices(newServices);
    }
  };

  // Ažuriranje odabranih servisa
  const handleServiceChange = (category, e) => {
    const { value, checked } = e.target;
    console.log(`Service ${value} in category ${category} ${checked ? 'checked' : 'unchecked'}`);

    if (checked) {
      // Dodaj servis (bez duplikata)
      if (!selectedServices.includes(value)) {
        console.log("Dodajem servis:", value);
        setSelectedServices([...selectedServices, value]);
      }
      
      // Ako kategorija nije selektovana, selektuj je
      if (!selectedCategories.includes(category)) {
        console.log("Automatski selektujem kategoriju:", category);
        setSelectedCategories([...selectedCategories, category]);
      }
    } else {
      // Ukloni servis
      console.log("Uklanjam servis:", value);
      setSelectedServices(selectedServices.filter(service => service !== value));
      
      // Proveri da li ima još selektovanih servisa iz ove kategorije
      const otherServicesInCategory = selectedServices
        .filter(service => service !== value) // Ukloni trenutni servis
        .filter(service => 
          services.some(s => s.service === service && s.category === category)
        );
      
      console.log("Preostali servisi u kategoriji", category, ":", otherServicesInCategory);
      
      // Ako nema više servisa iz ove kategorije, ukloni kategoriju
      if (otherServicesInCategory.length === 0) {
        console.log("Uklanjam kategoriju jer nema više selektovanih servisa:", category);
        setSelectedCategories(selectedCategories.filter(cat => cat !== category));
      }
    }
  };

  // Izračunaj koje kategorije imaju servise
  const getCategoriesWithServices = () => {
    if (!categories || !services) return [];
    
    const categoriesWithServices = categories.filter(category => 
      services.some(service => service.category === category.category)
    );
    
    console.log("Kategorije sa servisima:", categoriesWithServices.map(c => c.category));
    return categoriesWithServices;
  };

  const categoriesWithServices = getCategoriesWithServices();

  return (
    <div className="mt-2 max-h-64 overflow-y-auto">
      {categoriesWithServices.length > 0 ? (
        categoriesWithServices.map((category) => (
          <div key={category._id} className="category-section mb-2">
            <div className="flex items-center">
              <input
                type="checkbox"
                id={`category-${category._id}`}
                name="category"
                value={category.category}
                onChange={handleCategoryChange}
                checked={selectedCategories.includes(category.category)}
                className="mr-2"
              />
              <label htmlFor={`category-${category._id}`} className="text-white font-bold">
                {category.category}
              </label>
            </div>

            {/* Prikazivanje servisa bez obzira da li je kategorija selektovana */}
            <div className="ml-4">
              {services
                .filter((service) => service.category === category.category)
                .map((service) => (
                  <div key={service._id} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`service-${service._id}`}
                      name="service"
                      value={service.service}
                      onChange={(e) => handleServiceChange(category.category, e)}
                      checked={selectedServices.includes(service.service)}
                      className="mr-2"
                    />
                    <label 
                      htmlFor={`service-${service._id}`} 
                      className={`text-white ${selectedServices.includes(service.service) ? 'font-bold' : ''}`}
                    >
                      {service.service}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        ))
      ) : (
        <p className="text-white">Nema dostupnih kategorija sa servisima</p>
      )}
    </div>
  );
};

export default FinanceCategory;
