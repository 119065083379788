import React, {useState, useEffect, useCallback} from 'react'
import PhoneInput from 'react-phone-input-2';
import FileBase from 'react-file-base64';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import 'react-phone-input-2/lib/style.css';
import { createClient, updateClient } from '../redux/features/clientSlice';
import HeaderAdmin from '../components/HeaderAdmin';
import TimeInput from '../components/DatePicker';
import { updateSession } from '../redux/features/sessionSlice';
import { useTranslation } from 'react-i18next';

const initialState = {
    firstName:'',
    lastName:'',
    phone:'',
    email:'',
    date:'',
    time:'',
    price:'',
    workerFirstName:'',
    workerLastName:'',
    service:'',
    duration:''
}

const EditSession = ({goToClients})=> {
    
    const [sessionData, setSessionData] = useState(initialState);
    const [isOpenWorker, setIsOpenWorker] = useState(false);
    const [isOpenService, setIsOpenService] = useState(false);
    const [selectedWorker, setSelectedWorker] = useState(null); 
    const [selectedService, setSelectedService] = useState(null); 
    const error = useSelector((state) => state.session.error);
    const sessions = useSelector((state) => state.session.sessions);
    const user = useSelector((state) => state.auth.user);
    const workers = useSelector((state) => state.worker.workers);
    const services = useSelector((state) => state.service.services);
    const categories = useSelector((state) => state.category.categories);
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {id} = useParams();
useEffect(()=>{
    const singleSession = sessions.find((session)=> session._id === id);
    setSessionData({...singleSession})
},[])
    const { firstName, lastName, phone, email, price, date, time, duration,workerFirstName, workerLastName, service} = sessionData;
const choosenCategory = services.find((item)=> item.service === service);
console.log(choosenCategory);

    useEffect(()=>{
    error && toast.error(error)
    },[error])
    const toggleDropdownWorker = () => {
        setIsOpenWorker(!isOpenWorker);
      };
      const handleSelectWorker = (worker) => {
        
        setSelectedWorker(worker); 
        setSessionData((prevState) => ({
          ...prevState,
          workerFirstName: worker.firstName,
          workerLastName: worker.lastName,
      }));
        setIsOpenWorker(false); 
      };
      const toggleDropdownService = () => {
        setIsOpenService(!isOpenService);
      };
      const handleSelectService = (service) => {
        setSelectedService(service); 
          setSessionData((prevState) => ({
            ...prevState,
            service: service.service,
            price: service.price,

        }));
        setIsOpenService(false); 
      };
      const onInputChange = (e) =>{
        const {name, value} = e.target;
        setSessionData({...sessionData, [name]: value})
      };
        const handleTimeChange = useCallback((selectedTime) => {
            setSessionData((prevState) => ({
              ...prevState,
              time: selectedTime 
            }));
          });
      const handleSubmit = (e) =>{
          e.preventDefault();
            const updatedSessionData = {...sessionData, name:user?.result?.name}
            
        dispatch(updateSession({id, updatedSessionData, navigate, toast}))  
            handleClear();
            navigate('/admin')
          }
      const handleClear = () =>{
        setSessionData({firstName:'', lastName:'', phone:'', email:'', date:'', time:'', price:'',duration:'', workerFirstName:'', workerLastName:'', service:''})
      };

  return (
<div className="min-h-screen flex flex-col bg-gradient-to-br from-green-900 via-green-500 to-green-200 overflow-y-auto">
      {/* Header */}
      <HeaderAdmin />
      <div className="w-full md:p-8 p-4  fixed top-0 left-0 z-10">
        <h2 className="text-3xl font-bold text-white mb-6 mt-[80px]">Edit Session</h2>
        <form onSubmit={handleSubmit} className="space-y-6 w-full" noValidate>
            <div className="flex flex-row  w-full">
            <div className='md:w-1/2 w-full'>
            <label htmlFor="firstName" className="block text-xs font-medium text-gray-100">First Name</label>
            <input onChange={onInputChange} value={firstName} id="firstName"  name="firstName" type="text" className=" w-full px-2 py-1 border border-gray-200 rounded-md shad3w-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/2 w-full'>
            <label htmlFor="lastName" className="block text-xs font-medium text-gray-100">Last Name</label>
            <input onChange={onInputChange} value={lastName} id="lastName" name="lastName" type="text" className=" w-full px-2 py-1 border border-gray-200 rounded-md shad3w-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
            </div>
            <div className="flex flex-row w-full">
            <div className='md:w-1/2 w-full'>
  <label htmlFor="phone" className="block text-xs font-medium text-gray-200">Phone Number</label>
  <input
    type="tel"
    id="phone"
    name="phone"
    value={phone}
    onChange={onInputChange}
    className=" w-full px-4 py-1 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none"
    placeholder={t('sessions.phone')} 
  />
</div>
          <div className='md:w-1/2 w-full '>
            <label htmlFor="email" className="block text-xs font-medium text-gray-100">Email</label>
            <input onChange={onInputChange} value={email} id="email" name="email" type="text" className=" w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
</div>
</div>

<div className="flex flex-row  w-full">          
<div className='relative md:w-1/2 w-full '>
      <label htmlFor="service" className="block text-xs font-medium text-gray-100">Service</label>
      <div 
        className=" w-full px-2 py-1 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none bg-white text-black cursor-pointer"
        onClick={toggleDropdownService}
      >
        {service}
      </div>
      
      {isOpenService && (
        <ul 
          className="absolute bottom-full w-full max-h-40 bg-white border border-gray-500 rounded-smoverflow-auto z-10 shadow-lg"
        >
         {services.length > 0 ? (services.map((service) => (<li key={service._id} 
      className="flex justify-between cursor-pointer hover:bg-green-500 bg-green-100  text-black p-2"
      onClick={() => handleSelectService(service)}>
      {service.service} 
    </li>
  ))
) : (
  <p className="text-green-500 p-2">No services available.</p>
)}
<hr />
        </ul>
      )}
    </div>
    <div className='md:w-1/2 w-full'>
            <label htmlFor="price" className="block text-xs font-medium text-gray-100">Category</label>
            <input disabled value={choosenCategory?.category} id="price" name="price" type="text" title={t('services.categoryOfService')} className=" w-full px-2 py-1 bg-white border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
       
            </div>
            <div className="flex flex-row  w-full">
            <div className='md:w-1/2 w-full'>
            <label htmlFor="price" className="block text-xs font-medium text-gray-100">Price</label>
            <input onChange={onInputChange} value={choosenCategory?.price} id="price" name="price" type="text" className=" w-full px-2 py-1 border border-gray-200 rounded-md shad3w-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
          <div className='md:w-1/2 w-full'>
            <label htmlFor="duration" className="block text-xs font-medium text-gray-100">Duration</label>
            <input onChange={onInputChange} value={choosenCategory?.time} id="duration" name="duration" type="text" className=" w-full px-2 py-1 border border-gray-200 rounded-md shad3w-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
          </div>
           </div>
           <div className='relative md:w-1/2 w-full '>
      <label htmlFor="worker" className="block text-xs font-medium text-gray-100 ">Worker</label>
      <div 
        className=" w-full px-2 py-1 border border-gray-200 rounded-md shadow-sm focus:ring-2 focus:ring-green-400 focus:outline-none bg-white text-black cursor-pointer"
        onClick={toggleDropdownWorker}
      >
        {workerFirstName} {workerLastName}
      </div>
      
      {isOpenWorker && (
        <ul 
          className="absolute bottom-full w-full max-h-40 bg-white border border-gray-500 rounded-smoverflow-auto z-10 shadow-lg"
        >
         {workers.length > 0 ?  (workers.map((worker) => (
            <li 
              key={worker._id} 
              className="flex justify-between  cursor-pointer hover:bg-green-500 bg-green-100 text-black p-2"
              onClick={() => handleSelectWorker(worker)}
            >
            {worker.firstName} {worker.lastName}
            </li>
          ))):( <p className="text-green-500 p-2">No services available.</p>)}<hr />
        </ul>
      )}
    </div> 
            <div className="flex flex-row  w-full">
          
          <div className='md:w-1/2 w-full'>
             <label htmlFor="date" className="block text-xs font-medium text-gray-100">Date</label>
             <input onChange={onInputChange} value={date} id="date" name="date" type="date" required className=" w-full px-2 py-1 border border-gray-200 rounded-md shadow-xs focus:ring-2 focus:ring-green-400 focus:outline-none" />
 </div>
 <div className='md:w-1/2 w-full'>
             <label htmlFor="time" className="block text-xs font-medium text-gray-100">Time</label>
             
             <TimeInput oldTime={time} onTimeChange={handleTimeChange} />
 </div>
            </div>
          <button type="submit" className="w-full flex justify-center py-2 px-2 bor1er border-transparent rounded-md shadow-xs text-white bg-gradient-to-r from-green-700 to-green-500 hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400">
            Submit
          </button>
        </form>
      </div></div>
  )
}

export default EditSession;